@import "menu-content";
@import "menu-animations";
@import "menu-privacy-policy";

.menu {
  &__container {
    font-family: var(--hs-font-family-primary);
    width: 100%;
    height: 100%;
    max-height: 45rem;

    @media (min-width: $screen__l) {
      border-radius: $form_border-radius;
    }
    @media (max-width: $screen__l - 1) {
      @include asideMenu;
      position: fixed;
      top: 0;
      left: 0;
      right: 50%;
      height: 100vh;
      max-height: 100vh;
      z-index: 3;
      display: flex;
      flex-direction: column;

      .action__close {
        margin-left: auto;
        cursor: pointer;
        i {
          font-size: $font-size_default;
          opacity: $opacity_sub;
          @include transition(opacity);
          &:active {
            opacity: 1;
          }
        }
      }
    }
    @media (max-width: $screen__m - 1) {
      right: 40%;
    }
    @media (max-width: $screen__s - 1) {
      right: 20%;
    }
    @media (max-width: $screen__xs - 1) {
      right: 30%;
    }
    @media (max-width: 428px /* iPhone 6.7-inch */) {
      right: 14%;
    }
    @media (max-width: $screen__xxxs) /* iPhone 4-inch */ {
      right: 2%;
    }
  }
  &__block {
    &.user {
      padding: $indent-l $indent-s;
    }
    &.menu-categories {
      padding: $indent-xs $indent-s;
      position: relative;
      .menu-categories__item {
        padding: $indent-s 0;
        &.empty-link {
          display: none;
        }
        a,
        .item__login-create{
          color: var(--hs-text-color);
          @include add-icon($font-size_m, left);
          text-decoration: none;
          font-size: $font-size_default;
        }
        .item__login-create {
          a {
            padding-left: 0;
            &:not(:last-child) {
              &::after{
                content: "/";
                margin: 0 $text-indent;
              }
            }
          }
        }
      }
      .line {
        position: absolute;
        left: 0;
        width: 2px;
        background: currentColor;
        @include transition;
        transition-property: height, top, transform;
        transform: translateZ(0);
        transform-origin: 50% 0;
      }
    }
    @media (max-width: $screen__l - 1) {
      padding: $indent-m;
      &.switchers {
        display: flex;
      }
      &.menu-categories {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        overflow: auto;
        .menu-categories {
          &__item {
            padding: $indent-m 0;
            a,
            .item__login-create {
              color: var(--hs-text-color);
              @include add-icon($font-size_xl, left);
              text-decoration: none;
              font-size: $font-size_default;
            }
          }
        }
        .line{
          transform: translateZ(0) scaleY(0);
          &.is-ready{
            transform: translateZ(0) scaleY(1);
          }
        }
      }
    }
  }
}