@import "@/app/static/css/variables";

.toggler {
  font-size: 0.75rem;
  user-select: none;

  & > label {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .switcher-field {
    margin-left: 5px;
    height: 15px;
    width: 27px;
    border-radius: 10px;
    &::before,
    &::after {
      width: 11px;
      height: 11px;
    }
    &::after {
      top: 2px;
      left: 2px;
    }
    &.isActive {
      background-color: var(--hs-color-link);
      &::after {
        left: 14px;
      }
    }
  }
}

.theme-toggler {
  @extend .toggler;
  .switcher-field {
    margin: 0 5px;
    background: $color_main;
    &.isActive {
      background: #FB9750;
    }
  }
}