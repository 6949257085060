.toolbar {
  display: flex;
  align-items: baseline;
  font-family: var(--hs-font-family-primary);

  &__amount {

    .title,
    .amount {
      font-size: $font-size_s !important;
      text-transform: capitalize;
      line-height: $line-height_default;
    }

    .title {
      padding-right: $text-indent;
    }
  }

  &__sorter {

    .dropdown-menu,
    .dropdown-select {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__label {
        font-size: $font-size_s;
        padding-right: $text-indent;
        line-height: $line-height_default;
        cursor: pointer;

        @media (max-width: $screen__l) {
          display: none;
        }
      }

      .block {

        &__content .value,
        &__title input {
          font-size: $font-size_s;
          padding-right: $indent-xs;
        }

        &__title::after {
          display: none;
        }

        &__content {
          transform: none;

          &.dropdown-enter,
          &.dropdown-leave-to {
            transform: translate3d(0, 20px, 0);
            opacity: 0;
          }
        }
      }

      &::after {
        display: none;
      }

      padding-right: 0;
    }
  }

}

.module__page-filters {
  font-family: var(--hs-font-family-primary);

  .filter-item,
  .field {
    .price-result {
      text-transform: uppercase;
      text-align: center;
      font-size: $font-size_default;
      line-height: $line-height_default;
      margin-bottom: $indent-xs;

      &__min {
        &::after {
          content: '-';
          margin: 0 $text-indent;
        }
      }
    }

    .price-message {
      text-align: center;
      font-weight: $font-weight_light;
      font-size: $font-size_xs;
      margin-bottom: $indent-m;
    }

    .price-histogram {
      $_columns: 20;
      width: 100%;
      height: 100px;
      display: flex;
      align-items: flex-end;

      &__column {
        box-sizing: border-box;
        width: calc(100% / #{$_columns});
        background-color: var(--hs-border-color);
        @include transition(background-color);

        cursor: pointer;

        &:hover,
        &.is-active:hover {
          background-color: var(--hs-color-primary);
        }

        &.is-active {
          background-color: darken($color_fill-dark, 20%);
        }
      }
    }

    &__title {
      cursor: pointer;

      p {
        font-weight: $font-weight_semibold;
        font-size: $font-size_l;
        line-height: $line-height_default;
        position: relative;

        &::after {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translate3d(0, -50%, 0);
          font-family: $icon-font;
          font-size: $icon-size;
          content: $icon-angle-down;
          @include transition(transform)
        }
      }
    }

    &__content {
      overflow: hidden;

      .content-inner {
        padding: $text-indent 0 $indent-l;
      }

      &.price-range {
        padding: 0 $indent-m;
        user-select: none;

        .vue-slider-dot {
          cursor: grab;

          &:active {
            cursor: grabbing;
          }
        }
      }
    }

    &.is-active {
      p::after {
        transform: translate3d(0, -50%, 0) rotate(180deg);
      }
    }
  }
}

@media (max-width: $screen__l - 1) {

  // filters
  .module__page-filters {
    margin-top: 0;
    padding-top: $indent-m;

    .filter-item:first-child,
    .field:first-child {
      padding-top: 0;
    }
  }

  .toolbar {
    align-items: flex-start;
    position: sticky;
    top: 0;
    z-index: 5;

    * {
      font-family: var(--hs-font-family-primary);
    }

    &__button {
      padding: 0.78rem $indent-s;
      margin-right: $indent-xs;

      &_with-bg {
        padding: 0.85rem $indent-s;
      }

      .toggle {
        @include button-reset;
        @include activebg;
        display: flex;
        border-radius: $form_border-radius;
        font-size: $font-size_s;
      }

      &_with-bg {
        background: transparentize($color_fill-dark, $opacity_sub);

        .toggle {
          width: 100%;
          display: flex;
          justify-content: space-between;

          .text {
            margin-right: 0.5rem;
          }

          .number_of_filters {
            width: 1.25rem;
            height: 1.25rem;
            border-radius: 100%;
            background-color: var(--hs-color-primary);
            color: $color_light;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: -0.18rem;
          }
        }
      }

      &_with-icon {
        &.toolbar__promo {
          margin-right: auto;
        }

        .toggle {
          position: relative;
          text-align: left;

          span {
            margin-left: $indent-xs;
          }

          .dates-text {
            display: inline-block;
            margin-left: 0;
          }

          .dates-arrow {
            font-size: $font-size_xxs;
          }

          .promo-text {
            text-transform: uppercase;
          }
        }
      }
    }

    &__sorter {
      max-width: 160px;
    }
  }
}

@media(max-width: $screen__m - 1) {
  .module__page-filters {
    padding-top: 0;

    .field:first-child {
      padding-top: $indent-l;
    }

    .actions-toolbar {
      @include fullwidthline(top);
      position: sticky;
    }
  }

  .toolbar {
    flex-wrap: wrap;
    justify-content: space-between;

    &__button {
      margin-right: 0;

      &_with-icon {
        flex: 0 0 48%;
        margin: 0 0 $indent-s 0;

        &.toolbar__guests {
          margin-left: 0;
        }

        &.toolbar__dates {
          flex: 0 0 100%;
          margin: 0 0 $indent-s 0;
        }

        .toggle {
          width: 100%;

          span {
            margin-left: $font-size_xxxs;
          }

          .dates-text {
            font-size: $font-size_xs;
          }

          .dates-arrow {
            margin: 0 0.12rem;
            font-size: $font-size_xxxs;
          }
        }
      }
    }

    &__filters,
    &__sorter {
      flex: 0 0 48%;
      margin: 0 0 $indent-s 0;
      order: 1;
    }

    &__sorter {
      width: 100%;
      max-width: unset;
    }
  }
}
