.switcher {
  position: relative;
  &:not(:first-child) {
    margin-left: -1px;
  }
  .title {
    font-family: var(--hs-font-family-primary);
    font-weight: $font-weight_regular;
    font-size: $font-size_default;
    color: var(--hs-text-color);
    text-transform: uppercase;
    cursor: pointer;
    padding-left: $indent-m;
    @include transition(color);
    line-height: $line-height_default;
  }
  ul {
    font-family: $font-family_sans-serif;
    li{
      a{
        color: var(--hs-color-link);
      }
    }
  }
}

.header {
  &-wrapper {
    .is-mobile {
      position: relative;
    }
  }
  &__logo{
    a{
      display: inline-block;
      padding: $indent-s 0;
    }
    img{
      height: 50px;
    }
  }
  &__panel {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.auth-form{
  .header {
    &__logo{
      a{
        padding: $indent-m 0;
      }
    }
  }
}

.checkout {
  .header {
    &-wrapper {
      position: relative;
      .action.close{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 2.8rem;
        color: var(--hs-color-link);
        font-size: $font-size_default;
        text-decoration: none;
      }
    }
    &__logo {
      a {
        padding: $indent-l 0;
      }
    }
  }
}
.home-icon, .home-label {
  font-weight: $font-weight_regular;
  font-size: $font-size_default;
  color: var(--hs-color-link);
}
.home-label {
  font-family: var(--hs-font-family-primary)!important;
}
a{
  text-decoration: none;
}
@media (min-width: $screen__s) {
  .auth-form{
    .header{
      &-wrapper{
        border-bottom: none;
      }
    }
  }
}

@media (max-width: $screen__s - 1) {
  .auth-form{
    .header{
      &-wrapper{
        position: relative;
        .action.close{
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: $indent-s;
          color: var(--hs-color-link);
          font-size: $font-size_default;
          text-decoration: none;
        }
      }
    }
  }
}
