.module__search-rooms,
.module__search {
  .form-search-rooms {
    &__button--add-room {
      width: 100%;
      padding: $indent-xxs;
      margin-top: $indent-l;
    }
    &__button--search {
      width: 100%;
      padding: $indent-s;
      margin-top: $indent-xs;
    }
    .field__item {
      flex: 0 0 50%;
      &:not(:first-child) {
        margin-left: -1px;
      }
    }
    .fieldset {
      .field {
        .label__title,
        .dropdown-menu__label,
        .selected-room__label,
        .dropdown-select__label {
          margin-left: $indent-xxs;
        }
        &.date,
        &.guests,
        &.promo-code {
          input,
          select {
            font-size: $font-size_default;
          }
          .promo-code__btn_title {
            text-decoration: underline;
            &:hover {
              text-decoration: none;
            }
          }
        }
        &.date,
        &.guests,
        &.promo-code {
          .field__item {
            &:first-child {
              input,
              select {
                border-radius: $form_border-radius 0 0 $form_border-radius;
              }
            }
            &:not(:first-child) {
              input,
              select {
                border-radius: 0 $form_border-radius $form_border-radius 0;
              }
            }
            .label__title,
            .dropdown-menu__label,
            .selected-room__label,
            .dropdown-select__label {
              @include add-icon(
                $font-size_s,
                left,
                calc(#{$indent-m} + #{$indent-xxs})
              );
            }
          }
          .title {
            cursor: pointer;
          }
          .dropdown-menu,
          .dropdown-select {
            .block {
              &__title {
                padding-right: 0;
                input {
                  text-transform: capitalize;
                  padding-right: $indent-xl;
                }
                &::after {
                  display: none;
                }
              }
              &__content {
                border-radius: 2px;
                .value {
                  text-transform: capitalize;
                  padding: $indent-xs $indent-s;
                  font-size: $font-size_default;
                }
              }
            }
            &__label {
              font-size: $font-size_s;
              margin-bottom: $indent-xxs;
              line-height: $line-height_default;
              display: inline-block;
            }
          }
          .dropdown-menu__label {
            display: flex;
            justify-content: space-between;
            .label__icon-close {
              cursor: pointer;
              margin-right: 0.6rem;
            }
          }
          .dropdown-menu {
            .block__title {
              transform-origin: 50% 0;
            }
            &.is-active {
              .dropdown-menu__label {
                transform: translate3d(0, 0, 0);
              }
              .block__title {
                transform: translate3d(0, -10px, 0);
                opacity: 0;
              }
            }
            .block {
              &__content {
                max-height: 175px;
                transform: translate3d(0, 20px, 0);
                &.dropdown-enter,
                &.dropdown-leave-to {
                  pointer-events: none;
                  transform: translate3d(0, 40px, 0);
                  opacity: 0;
                }
              }
            }
          }
          .dropdown-select {
            padding: 0;
            &::after {
              display: none;
            }
            i {
              transform: none;
              top: 45px;
              right: $indent-s;
            }
          }
        }
        &.guests {
          &.inputs {
            &-enter-active {
              @include transition;
              transition-property: transform, opacity, height;

              @for $i from 3 through 10 {
                &:nth-child(#{$i}) {
                  transition-delay: #{calc(($i - 3) / 10)}s;
                }
              }
            }
            &-enter {
              transform: translateY(-$indent-s);
              opacity: 0;
            }
          }
          margin-top: $indent-l;
          .field {
            &__item {
              flex: 1 0 100%;
              &.is-booked {
                opacity: $opacity_sub;
                pointer-events: none;
              }
            }
            &__control {
              flex: 1 0 100%;
            }
          }
          .dropdown-menu,
          .selected-room {
            .block {
              &__title {
                padding: 9px $indent-s;
                // padding-right: $indent-l;
                font-size: $font-size_default !important;
                // line-height: $line-height_default;
                border-radius: $form_border-radius;
                @include transition;
                transition-property: color, transform, opacity;

                span:not(:last-child) {
                  // height: 20px;
                  margin-right: $indent-xxs;
                  &::after {
                    content: ',';
                  }
                }
              }
              &__content {
                padding: $indent-m;
                max-height: none;
                .content-inner {
                  max-height: none;
                }
                .actions-toolbar {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  margin-top: $indent-m;
                  .action {
                    &.tertiary {
                      button {
                        font-weight: $font-weight_regular;
                        font-size: $font-size_s;
                      }
                    }
                    &.primary {
                      margin-bottom: 0;
                      button {
                        background-color: transparent;
                        color: var(--hs-color-link);
                        font-weight: $font-weight_bold;
                        padding: 0;
                      }
                    }
                    button {
                      cursor: pointer;
                      @include transition(opacity);
                      &:hover {
                        opacity: 0.7;
                      }
                      &:active {
                        opacity: 1;
                      }
                    }
                  }
                }
              }
            }
          }
          .dropdown-menu__label {
            display: flex;
            justify-content: space-between;
          }
        }
        &.rooms {
          .field__item {
            .label__title,
            .dropdown-menu__label,
            .selected-room__label,
            .dropdown-select__label {
              padding-left: 0;
            }
          }
          .dropdown-menu {
            .block {
              &__title {
                input {
                  border-radius: $form_border-radius;
                }
              }
            }
          }
        }
        &.promo-code {
          &.is-booked {
            opacity: $opacity_sub;
            pointer-events: none;
          }
          .field__item {
            flex: 0 0 100%;
          }
          .field {
            &__control {
              width: 100%;
              input {
                text-transform: uppercase;
              }
            }
          }
        }
        &.date {
          .dropdown-menu::before {
            display: none;
          }
          position: relative;
          .date__difference {
            position: absolute;
            right: $text-indent;
            top: calc(100% + #{$text-indent});
            p {
              font-size: $font-size_s;
              line-height: $line-height_default;
            }
          }
        }
      }
    }

    & > .actions-toolbar,
    & > .fieldset {
      margin-top: 0.6rem;
    }

    .input-btn-group {
      display: flex;
      margin-bottom: 1.5rem;

      .field {
        margin-bottom: 0;
        flex-basis: 70%;
        input {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      &__btn {
        flex-basis: 30%;
        button {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }
}

.selected-room {
  &__label {
    font-size: $font-size_s;
    margin-bottom: $indent-xxs;
    line-height: $line-height_default;
    display: flex;
    cursor: pointer;

    .button-container {
      margin-left: auto;
      padding-right: $text-indent;
      font-size: $font-size_s;
      font-weight: $font-weight_light;
    }
  }
  &__description {
    padding: $indent-xs;
  }
  &__room-name {
    font-size: $font-size_m;
  }
  &__occupancy {
    margin-top: $indent-xs;
  }
  &__rateplan {
    margin-top: $indent-xxs;
    font-size: $_font-size_includes;
    font-weight: $font-weight_semibold;
  }
  &__price {
    float: right;
    font-size: $font-size_s;
  }
}

.module__aside-menu {
  .form {
    .fieldset {
      .field {
        flex-wrap: initial !important;
      }
    }
  }
  &.promo-code {
    .field {
      padding: 1.2rem; // variable?
      &__item {
        margin-top: 12px;
        .label__title {
          font-family: var(--hs-font-family-primary);
          font-size: 1.1rem;
          line-height: 1.4em;
          text-align: center;
          [class^='icon-'] {
            display: none;
          }
        }
      }
      &__control {
        input {
          @include text-input;
          text-transform: uppercase;
        }
      }
    }
  }
  .room-guests-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1.2rem 0 0;
    &__text {
      font-size: 1.2rem;
    }
    &__icon {
      font-size: 0.9rem;
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media (min-width: $screen__l) {
  .module__search-rooms,
  .module__search {
    .module__content {
      display: flex;
      flex-grow: 1;
      .date__check-out {
        .datepicker-popup {
          left: -100%;
        }
      }
    }
  }
}

@media (max-width: $screen__l - 1) {
  .module__aside-menu {
    &.date {
      .module__content {
        padding-top: $indent-m;
      }
    }
    .guests {
      margin-top: 1.2rem;
    }
  }
  .module__search-rooms .module__content {
    width: 100%;
  }
}
