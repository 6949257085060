
@import '@/app/static/css/variables';

.v-data-table {
  @media (min-width: $screen__xs) {
    tr,
    td {
      padding-left: 8px !important;
    }
  }
}

.v-stepper__step {
  cursor: pointer;
}

.reservation-modification {
  min-height: 80vh;
  .modification-deadline-text {
    opacity: $opacity_disabled;
  }
}
