
@import '@/app/static/css/variables';
@import '@/app/static/css/mixins/templates';

.slide {
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    @include transition(all, $transition-time);
    position: absolute;
    top: 0;
    left: 0;
    width: 130%;
    height: auto;
    background-color: var(--v-primary-base);
    transform: translate(-110%, 0) skew(-30deg);
  }

  &:hover {
    &::before {
      opacity: 1 !important;
      transform: translate(-5%, 0) skew(-15deg);
    }

    .inner {
      color: var(--v-accent-lighten5);
    }
  }
}

.inner {
  position: relative;
  @include transition(all, $transition-time);
}
