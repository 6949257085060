
@import "@/app/static/css/variables";
.order-receipt {
  display: flex;
  flex-direction: column;
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .total {
      font-weight: $font-weight_bold;
    }
  }
}
