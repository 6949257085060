$_page-transition-time: $transition-time * 1.2;
$_page-transition-timing-function: ease-out;

.page__content {
  transition: all $_page-transition-time;
  .shine {
    will-change: opacity;
    transform: translateZ(0);
    transition: opacity $_page-transition-time $_page-transition-timing-function,
                visibility 0s $_page-transition-timing-function $_page-transition-time;
    opacity: 0;
    visibility: hidden;
    img {
      //transition: filter $_page-transition-time/2 $_page-transition-timing-function;

    }
  }
}

.page-enter {
  .shine {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
    img {
      //filter: blur(10px)
    }
  }
  &-to {
    .shine {
      opacity: 0;
      visibility: hidden;
    }
  }
}

.page-leave {
  .shine {
    opacity: 0;
    visibility: hidden;
  }
  &-to {
    .shine {
      opacity: 1;
      visibility: visible;
      transition-delay: 0s;
    }
  }
}

.page-leave-active,
.rooms-item-leave-active{
  .room__images {
    position: relative;
    .image__container {
      position: absolute;
      top: 0;
      left: 0;
      &.tns-slide-active {
        z-index: 1;
      }
    }
  }
  .item__images{
    position: relative;
    .image__container{
      position: absolute;
      top: 0;
      left: 0;
      margin: $indent-m 0;
      &.tns-slide-active{
        z-index: 1;
      }
    }
  }
}

@media (max-width: $screen__s - 1) {

  .page-leave-active,
  .rooms-item-leave-active{
    .item__images{
      .image__container{
        margin: $indent-s 0;
      }
    }
  }

}