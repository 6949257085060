$_fade-transition-time: $transition-time;
$_fade-transition-timing-function: $transition-timing-function;

.fade-enter-active,
.fade-leave-active {
	transition: transform, opacity;
	transition-duration: $_fade-transition-time;
	transition-timing-function: $_fade-transition-timing-function;
}

.fade-enter,
.fade-leave-active {
	opacity: 0;
}
