@import "./ui/forms";
@import "./ui/text";
@import "./ui/icons";
@import "./ui/modal";
@import "./ui/aside-menu";
@import "./ui/page-animations";
@import "./ui/sweet-alert";
@import "./ui/slider";
@import "./ui/inputs";
@import "./ui/dropdown";
@import "./ui/searchable-selects";
@import "./ui/toggler";
@import "./ui/menu";

* {
  margin: 0;
  padding: 0;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;

  &::selection {
    background: rgba($color_main, 0.7);
    color: $color_light;
  }



}

html,
body {
  background: var(--hs-body-bg);
  background-attachment: fixed;
  color: var(--hs-text-color);
  -webkit-background-size: cover !important;
  background-size: cover !important;
  scroll-behavior: smooth;
  width: 100%;
  font-family: var(--hs-font-family-primary);
}

#app.v-application {

  .display-4,
  .display-3,
  .display-2,
  .display-1,
  .headline,
  .title,
  .subtitle-1,
  .subtitle-2,
  .body-1,
  .body-2,
  .caption,
  .overline {
    font-size: inherit !important;
    font-family: var(--hs-font-family-primary) !important;
  }

  p .title {
    font-size: 14px !important;
  }
}

.custom-container {
  max-width: 1440px !important;
}

.gradient-layer {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  /* Allows interaction with underlying card content */
}

._has-modal {

  &,
  & body {
    overflow: hidden;
  }
}

.bolder {
  font-weight: $font-weight_semibold;
}

@media (max-width: $screen__s - 1) {
  body {

    &._auth-form,
    &._checkout,
    &._cancel {
      background-color: transparent;
    }
  }
}

.swal-alert-popup {
  padding: 1.25rem 0;

  .swal-button {
    font-size: 0.625rem;
  }
}
