.info-line {
  position: sticky;
  top: 0;
  z-index: 1;
  border-bottom: 1px solid var(--hs-border-color);
}

.module__info-line {
  .info-line {
    &__heading {
      padding-bottom: 10px;
      .value {
        font-size: $font-size_default;
        font-weight: $font-weight_semibold;
        white-space: nowrap;
        margin-right: $indent-xs;
      }
    }
    &__link {
      a {
        font-size: $font-size_s;
        white-space: nowrap;
        color: var(--hs-color-link);
        opacity: $opacity_main;
        @include transition(opacity);
        text-decoration: none;
        &:hover {
          opacity: 1;
        }
      }
    }
    &__item {
      height: 1.25rem;
      margin: 0 $indent-xs $indent-xs 0;
      position: relative;
      flex-shrink: 1;
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: $font-size_default;
      &.dates {
        height: auto;
        justify-content: center;
        box-sizing: content-box;
        padding: $indent-xs $indent-xs $indent-xs $indent-l;
        border: 1px solid var(--hs-border-color);
        border-radius: $form_border-radius;
      }
      .item {
        &__icon {
          font-size: $font-size_s;
        }
        &__key {
          flex-basis: 40%;
        }
        &__value {
          text-align: right;
        }
      }
      .dates {
        &__arrow {
          margin: 0 $indent-xxs;
          font-size: $font-size_xs;
        }
      }
      .guests__value {
        &:not(:last-child) {
          margin-right: $indent-xxs;
          &::after {
            content: ',';
          }
        }
        .value {
          margin-right: $indent-xxs;
        }
      }
    }
  }

}

@media (max-width: $screen__s - 1) {
  .module__info-line {
    padding: 0;
    flex-wrap: wrap;
    align-items: center;

    .info-line{
      &__heading,
      &__link{
        order: -1;
      }
    }
    .info-line__item {
      margin-right: 0;
      width: 100%;
      margin-bottom: $indent-xs;
    }
  }
}
