.config {
  font-family: "Open Sans HSB", sans-serif;
  padding: 0.8rem;

  .block__title {
    margin-bottom: 2.9rem;

    .title {
      h2 {
        font-size: 1.9rem;
        color: $color_main;
      }
    }
  }
}

.customization {
  &__title {
    color: $color_main;
  }

  &__block {
    overflow: hidden;
    position: relative;

    .dropdown-menu {
      .block__title {
        margin-bottom: 0;
      }
    }

    &--preview {
      width: 66%;
      margin-right: 0;
      position: relative;

      .disable-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
      }

      .all-rooms {
        border: 1px solid #E4E4E4;
        background: var(--hs-body-bg);
        color: var(--hs-text-color);
        -webkit-background-size: cover !important;
        background-size: cover !important;
      }
    }

    &--form {
      @include transition(all, $transition-time, ease-in-out);
      box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
      flex: none;
      position: fixed;
      right: 0;
      top: 90px;
      margin-top: 40px;
      background: $color_light;
      border: 1px solid #E4E4E4;
      z-index: 100;
      max-height: calc(100vh - 100px);
      width: 33%;
      overflow: auto;

      &.hide {
        transform: translateX(100%);
      }

      .fieldset {
        margin-bottom: 13.4px;

        .radio-group {
          flex-wrap: nowrap;
          label {
            &:first-of-type {
              font-size: 0.6rem;
            }
            &:nth-of-type(2) {
              font-size: 0.8rem;
            }
          }
        }
      }
      .dropdown-menu {
        .block__title {
          padding: 8px 0.9rem;
          margin-bottom: 0;
          border: 1px solid #E4E4E4;
          border-radius: 5px;

          &::after {
            display: none;
          }
        }
      }

      .font-family__example {
        padding: 0.625rem 1rem 0.9rem;
        border: 1px solid #e4e4e4;
        border-bottom: none;
        margin-bottom: -2px;
      }
    }
  }

  &__title {
    font-size: 1.9rem;
    font-weight: 300;
    margin-bottom: 0.9rem;
  }

  &__row {
    flex-wrap: nowrap;
  }

  &__toggle {
    position: fixed;
    top: 1.9rem;
    right: 0.8rem;
    background: #eeeeee;
    border: none;
    padding: 0.5rem 0.75rem;
    z-index: 100;
  }
}

@media (min-width: $screen__s) {
  .config {
    .block-form {
      padding: 1.9rem;
    }
  }
  .customization {
    &__block {
      .col {
        padding: 0 0.8rem;
      }
    }
  }
}

@media (max-width: $screen__xl) {
  .customization {
    &__block {
      &--preview {
        width: 100%;

        .all-rooms {
          zoom: 0.7;
        }
      }
    }
  }
}

@media (min-width: $screen__xl) {
  .customization {
    &__block {
      &--preview {
        margin-right: 1.9rem;
        .all-rooms {
          zoom: 0.5;
        }
      }
      &--form {
        position: static;
        border: none;

        &.hide {
          transform: translateX(0);
        }

        .fieldset {
          &__item {
            .field__input {
              color: $color_main;
            }
          }
          &__item-title {
            font-size: 2rem;
          }
        }
      }
    }
    &__toggle {
      display: none;
    }
  }
}
