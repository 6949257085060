@import '_lib';
@import '_fonts';
@import '_ui';
@import '_themes';
@import '_transitions';

.page-wrapper {
  @import '_components';
  //font-size: var(--hs-font-size-primary);
  font-family: var(--hs-font-family-primary);
}
