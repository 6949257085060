
@import '@/app/static/css/variables';

.order-info {
  .info__number {
    margin-bottom: $indent-m;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-weight: $font-weight_light;
      font-size: $font-size_m;
      line-height: $line-height_default;
    }

    .count {
      font-size: var(--hs-font-size-title);
      font-family: var(--hs-font-family-title);
      letter-spacing: 3px;
      line-height: $line-height_default;
    }
  }
}
