.form {
  .fieldset {
    .field {
      font-family: var(--hs-font-family-primary);
      display: flex;
      flex-wrap: wrap;
      &:not(:last-child) {
        margin-bottom: $indent-s;
      }
      &__item {
        box-sizing: border-box;
      }
      .label {
        cursor: pointer;
        &__title {
          font-size: $font-size_s;
          margin-bottom: $indent-xxs !important;
          line-height: $line-height_default;
        }
      }
      input, select, textarea, .field__dropdown {
        @include text-input;
      }
      .field__dropdown.invalid {
        border-color: var(--v-error-base)
      }
      textarea {
        height: 160px;
      }
      &__control {
        box-sizing: border-box;
      }
      &__error {
        padding: 8px 12px 0;
        font-size: $font-size_s;
        color: var(--v-error-base);
        @include transition;
        transition-property: opacity, transform;

        &.error-enter,
        &.error-leave-to{
          opacity: 0;
        }
      }
    }
  }
  &.scale-label {
    .fieldset {
      .field {
        margin-bottom: $indent-s;
        &__control {
          position: relative;
          flex-grow: 1;
          input, textarea {
            font-size: $font-size_default;
          }
          .label {
            position: absolute;
            top: 1.1rem;
            left: $indent-s;
            font-size: $font-size_default;
            opacity: $opacity_sub;
            margin-bottom: 0;
            white-space: nowrap;
            line-height: 1em;
            transform-origin: 0 0;
            @include transition(transform);
            pointer-events: none;
            transform: translate3d(0, 0, 0) scale(1);
          }
          .toggle {
            position: absolute;
            right: $indent-s;
            top: 1.1rem;
            opacity: $opacity_sub;
            font-size: $font-size_s;
            z-index: 4;
            cursor: pointer;
            @include transition;
            transition-property: opacity, color;

            &:hover {
              opacity: 1;
            }
          }
        }
        &__description {
          margin-top: $indent-xs;
          font-weight: $font-weight_bold;
          opacity: $opacity_sub;
          font-size: $font-size_s;
          padding: 0 $indent-s;
        }
        &.has-error {
          input, textarea {
            border: 2px solid var(--v-error-base) !important;
            color: var(--v-error-base);
          }
          .label {
            color: var(--v-error-base);
          }
        }
      }
    }
  }
}
