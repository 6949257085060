.searchable-select {
  display: flex;
  align-items: center;
  height: 20px;
  padding: 0 15px;

  &:first-child {
    padding-left: 0;
  }

  .v-autocomplete {
    .v-input__control {
      .v-input__slot {
        .v-select__slot {
          &:hover {
            .v-label:not(.v-label--active),
            .v-icon {
              opacity: $opacity_sub;
            }
          }
        }
      }
    }
  }
}
