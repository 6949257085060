@import 'tiny-slider';
@import 'room-info';

$_controls-main-color: #fefefd;
$_controls-opacity: 0.4;
$_controls-hover-opacity: 0.8;
$_controls-background-opacity: 0.3;
$_controls-background: transparentize($color_main,
    (1 - $_controls-background-opacity));
$_controls-hover-background: transparentize($color_main,
    (1 - $_controls-hover-opacity));
$_controls-active-background: $_controls-background;
$_controls-color: transparentize($_controls-main-color, $_controls-opacity);
$_controls-hover-color: $_controls-main-color;
$_controls-width: 3.75rem;

$_controls_transition-time: 0.25s;

$_nav-dot_size: 0.3rem;
$_nav-dot_border-radius: 50%;
$_nav-dot_background: $color_light;
$_nav-dot_opacity: 0.3;
$_nav-dot-hover_opacity: 0.6;
$_nav-dot-active_opacity: 1;

$_prev-icon: $icon-slider-arrow-left;
$_next-icon: $icon-slider-arrow-right;

.module__room [data-role='slider-container'] {
  .tns-outer {
    .tns-controls {
      button {
        color: $_controls-color;
        width: $_controls-width + $indent-s;
        background-color: transparent;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          transform: translate3d(0, -50%, 0);
          width: $_controls-width;
          height: $_controls-width;
          border-radius: 50%;
          background-color: $_controls-background;
          transition-property: transform, background-color, border-radius, width,
            height;
          transition-duration: $_controls_transition-time;
          transition-timing-function: $transition-timing-function;
        }

        &[data-controls='prev'] {
          &::after {
            left: calc($indent-s + $_controls-width / 2);
            @include transition(left, $_controls_transition-time);
          }

          &::before {
            right: 0;
          }

          &::after {
            left: 60%;
          }

          &:hover {
            &::after {
              left: 50%;
            }
          }
        }

        &[data-controls='next'] {
          &::after {
            left: auto;
            right: calc($indent-s + $_controls-width / 2);
            @include transition(right, $_controls_transition-time);
            transform: translate3d(50%, -50%, 0);
          }

          &::before {
            left: 0;
          }

          &::after {
            right: 60%;
          }

          &:hover {
            &::after {
              right: 50%;
            }
          }
        }

        &:hover {
          color: $_controls-hover-color;

          &::before {
            border-radius: 0;
            width: 100%;
            height: 100%;
          }
        }

        &:active {
          background-color: $_controls-active-background;
          color: $_controls-color;
        }
      }
    }
  }
}

.rooms {
  &-list {
    padding-top: $section-indent;

    &-padded {
      padding-top: $section-indent;
      margin-bottom: $section-indent;
    }
  }

  &-container {
    padding-bottom: $indent-l;

    >.actions-toolbar {
      background-color: $color_light;
      position: sticky;
      bottom: 0;
      padding: $indent-s 0;
      z-index: 4;

      .action {
        &.primary {
          margin-bottom: $indent-xs;
        }
      }
    }

    &__list {
      position: relative;
      .text-all_loaded {
        text-align: center;
        font-size: $font-size_m;
        line-height: 1.8em;
        color: var(--hs-text-color);
      }
    }

    .error-msg {
      position: relative;
      font-family: var(--hs-font-family-primary);
      text-align: center;
      padding: $indent-l 0;

      strong {
        font-weight: $font-weight_semibold;
        font-size: 100px;
        color: #f8f8f8;
        opacity: 0.8;
      }

      p {
        font-size: $font-size_default;
        line-height: $line-height_default;
      }
    }
  }

  &-item {
    display: flex;
    @include transition;
    transition-property: opacity, transform;

    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        transition-delay: #{calc($i / 20)}s;
      }
    }

    .item {
      &__label {
        position: absolute;
        z-index: 3;
        left: 0;
        top: $indent-m + $indent-s;
        max-width: 70%;
        font-size: 0.625rem;
        padding: $indent-xxs $indent-xs;
        font-family: var(--hs-font-family-primary);

        &_success {
          background-color: var(--v-success-base);
        }

        &_notice {
          background-color: var(--v-warning-lighten2);
        }

        &_error {
          background-color: var(--v-error-base);
        }

        .value {
          text-transform: uppercase;
          font-weight: $font-weight_semibold;
          color: $color_light;
          line-height: $line-height_default;
        }
      }

      &__images {
        flex-grow: 1;
        max-width: 45%;
        height: 260px;
        position: relative;

        .image__container {
          &:not(.tns-item):not(.single) {
            position: absolute;
            top: 0;
            left: 0;
            margin: $indent-m 0;
          }

          &.is-active,
          &:first-child {
            z-index: 1;
          }

          height: 220px;
          width: 100%;
          background-color: transparent;
          display: inline-block;

          img.img-cover {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      &__content {
        flex-grow: 1;
        max-width: 55%;
        display: flex;
        flex-direction: column;

        .actions-toolbar {
          margin-top: auto;
          width: 55%;
          text-align: center;
        }
      }
    }
  }

  &-wrapper {
    padding: $section-indent 15px 0;
  }
}

.room {
  &__images {
    overflow: hidden;

    .image__container {
      width: 100%;
      min-height: 15vh;
      height: 56vh;
      display: inline-block;
      background-color: transparent;

      &.is-active,
      &:first-child {
        z-index: 1;
      }

      img {
        cursor: pointer;
        @include transition(transform, $transition-time_longer * 2);
      }

      img.img-cover {
        width: 100%;
        height: 100%;
        object-fit: scale-down;
        object-position: center;
      }

      &.is-active {
        img {
          transform: scale(1.05);
        }
      }

      &.single {
        img.img-cover {
          width: 100vw;
          min-height: inherit;
          max-height: inherit;
        }
      }
    }

    .modal-mask {
      background-color: rgba(0, 0, 0, 0.8);

      .modal-header {
        z-index: 1;
        color: $color_light;

        .modal-close {
          color: inherit;
        }
      }

      .modal-body {
        flex-grow: 1;
        height: 0;
        display: flex;
        align-items: center;
        margin-top: -70px;
      }

      .image__container img {
        cursor: auto;
        object-fit: scale-down;
        object-position: center;
      }

      .modal-slider {
        position: relative;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;

    .cancellation-policy {
      white-space: pre-line;
    }
  }

  &__main-info {
    flex-grow: 1;
  }

  &__booking-info {
    flex-basis: 270px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .price-wrapper {
      text-align: center;
      padding-top: 0;
    }
  }
}

[data-role='slider-container'] {
  .tns-outer {
    position: relative;
    outline: none;

    .tns-controls {
      outline: none;

      button {
        opacity: 0;
        @include button-reset();
        font-size: $font-size_l;
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        width: $_controls-width;
        color: $_controls-color;
        transition: color, transform, background-color, opacity;
        transition-duration: $_controls_transition-time;
        transition-timing-function: $transition-timing-function;
        pointer-events: none;

        &::after {
          font-family: $icon-font;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate3d(-50%, -50%, 0);
        }

        &:hover {
          color: $_controls-hover-color;
        }

        &:active {
          color: $_controls-color;
        }

        &[data-controls='prev'] {
          left: 0;
          transform: translate3d(-100%, 0, 0);

          &::after {
            content: $_prev-icon;
          }

          &:active {
            transform: translate3d(-5px, 0, 0);
          }
        }

        &[data-controls='next'] {
          right: 0;
          transform: translate3d(100%, 0, 0);

          &::after {
            content: $_next-icon;
          }

          &:active {
            transform: translate3d(5px, 0, 0);
          }
        }

        &:disabled {
          opacity: 0;
        }
      }
    }

    &:hover {
      .tns-controls {
        button {
          opacity: 1;
          transform: translate3d(0, 0, 0);
          pointer-events: auto;
        }
      }
    }

    .tns-nav {
      position: absolute;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      bottom: $indent-s;
      z-index: 1;
      display: flex;
      align-items: center;

      button {
        @include button-reset;
        @include transition(opacity);
        display: block !important;
        width: $_nav-dot_size;
        height: $_nav-dot_size;
        border-radius: $_nav-dot_border-radius;
        opacity: $_nav-dot_opacity;
        background: $_nav-dot_background;
        margin: $indent-xxs;

        &:hover {
          opacity: $_nav-dot-hover_opacity;
        }

        &.tns-nav-active {
          opacity: $_nav-dot-active_opacity;
        }
      }
    }
  }
}

@media (max-width: $screen__l - 1) {
  .rooms-list {
    padding-top: 0;
  }
}

@media (max-width: $screen__m - 1) {
  .module__room .room__images .image__container {
    height: unset;
  }
}

@media (max-width: $screen__s - 1) {

  //rooms-list
  .rooms-list-wrapper {
    margin-bottom: $indent-l * 2;
  }

  .rooms-container {
    &__list {
      .rooms-item {
        flex-wrap: wrap;
        border-bottom: none;

        .item__content {
          border-bottom: none;
        }
      }

      .item {
        &__images {
          flex: 0 0 100%;
          max-width: 100%;
        }

        &__content {
          padding: 0 0 $indent-s 0;
          max-width: 100%;

          .options {
            font-weight: $font-weight_semibold;
          }

          .title-wrapper {
            .price-box {
              display: flex;
              flex-direction: column;
              align-items: flex-end;

              .price__old,
              .price__discount {
                margin-right: 2px;
              }
            }
          }

          .price__wrapper {
            .price-box {
              .price__discount .price-count {
                display: flex;
                color: var(--hs-text-color);
              }
            }
          }

          .includes-wrapper {
            padding-bottom: $indent-xs;
          }

          .messages-wrapper {
            padding-top: 0;
          }

          .actions-toolbar {
            width: 100%;
          }
        }
      }
    }
  }

  .module__room {
    .room {
      &__content {
        margin-bottom: $section-indent;
      }

      &__actions-block {
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;

        .actions-toolbar {
          width: 92%;
          margin: 0 auto;
        }
      }
    }
  }
}
