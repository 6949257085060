
@import '@/app/static/css/variables';
@import '@/app/static/css/mixins/templates';

.reservation-header {
  position: relative;

  .header-wrapper {
    @include flex-center;
  }

  a.action {
    position: absolute;
    top: 50%;
    left: $indent-xl;
    transform: translateY(-50%);
    padding: 10px;
    color: var(--hs-color-link);
    font-size: $font-size_default;
    text-decoration: none;
    cursor: pointer;
    @include transition;

    &:hover {
      opacity: $opacity_sub;
    }

    @media screen and (max-width: $screen__xs - 1) {
      left: $indent-s;
    }

    @media screen and (max-width: $screen__xxs - 1) {
      left: $indent-xxs;
    }
  }
}
