.privacy-policy {
  font-size: $font-size_default;
  h1 {
    font-size: var(--hs-font-size-title);
    font-family: var(--hs-font-family-title);
    margin: $indent-xs 0;
  }
  h2 {
    font-size: $font-size_l;
    line-height: $line-height_text;
  }
  h3 {
    font-size: $font-size_m;
  }
  p {
    margin: $indent-xs 0;
    line-height: $line-height_default;
    text-align: justify;
  }
  em {
    font-style: italic;
  }
  b,
  strong {
    font-weight: $font-weight_bold;
  }
  ol {
    list-style-type: decimal;
    list-style-position: inside;
    ol {
      list-style-type: lower-alpha;
      ol {
        list-style-type: lower-roman;
      }
    }
  }
  li {
    font-weight: $font-weight_bold;
    margin: $indent-xs 0 $indent-xs $indent-xs;
    text-align: justify;
    p {
      display: inline;
      font-weight: $font-weight_regular;
    }
  }
  code {
    font-family: monospace, monospace;
  }
}
