//icons
$icon-font: 'icomoon';

//icons-content
$icon-active-mark: "\e900";
$icon-angle-down: "\e901";
$icon-angle-left: "\e902";
$icon-angle-right: "\e903";
$icon-angle-up: "\e904";
$icon-arrow-right: "\e905";
$icon-baby-bed: "\e906";
$icon-balcony: "\e907";
$icon-breakfast: "\e908";
$icon-calendar: "\e909";
$icon-clock: "\e90a";
$icon-close: "\e920";
$icon-contact-us: "\e922";
$icon-error: "\e90b";
$icon-facebook: "\e90c";
$icon-for-family: "\e90d";
$icon-free-wi-fi: "\e90e";
$icon-garbage: "\e925";
$icon-google-plus: "\e90f";
$icon-hair-dryer: "\e910";
$icon-iron: "\e921";
$icon-mini-bar: "\e911";
$icon-minus: "\e924";
$icon-my-account: "\e912";
$icon-my-details: "\e913";
$icon-my-orders: "\e914";
$icon-no-smoking: "\e915";
$icon-notifications: "\e916";
$icon-notice: "\e90a";
$icon-payment-methods: "\e917";
$icon-person: "\e918";
$icon-person-outline: "\e928";
$icon-person-bold: "\e929";
$icon-pets-friendly: "\e91f";
$icon-plus: "\e923";
$icon-shampoo: "\e919";
$icon-sign-out: "\e91a";
$icon-slider-arrow-left: "\e91b";
$icon-slider-arrow-right: "\e91c";
$icon-success: "\e91d";
$icon-terms-conditions: "\e91e";
$icon-spinner2: "\e97b";
$icon-phone: "\e926";
$icon-mail: "\e927";
$icon-pricetags: "\e92a";
$icon-eye: "\e9ce";
$icon-eye-blocked: "\e9d1";
$icon-heart: "\e9da";
$icon-office: "\e92d";
$icon-sort-amount-asc: "\ea4c";
$icon-sort-amount-desc: "\ea4d";
$icon-bed: "\e92b";
$icon-home: "\e92c";
$icon-info: "\ea0c";

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: $icon-font !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-home3:before {
  content: $icon-home;
}

.icon-active-mark:before {
  content: $icon-active-mark;
}

.icon-angle-down:before {
  content: $icon-angle-down;
}

.icon-angle-left:before {
  content: $icon-angle-left;
}

.icon-angle-right:before {
  content: $icon-angle-right;
}

.icon-angle-up:before {
  content: $icon-angle-up;
}

.icon-arrow-right:before {
  content: $icon-arrow-right;
}

.icon-baby-bed:before {
  content: $icon-baby-bed;
}

.icon-balcony:before {
  content: $icon-balcony;
}

.icon-breakfast:before {
  content: $icon-breakfast;
}

.icon-calendar:before {
  content: $icon-calendar;
}

.icon-clock:before {
  content: $icon-clock;
}
.icon-close:before {
  content: $icon-close;
}

.icon-contact-us:before {
  content: $icon-contact-us;
}

.icon-error:before {
  content: $icon-error;
}

.icon-facebook:before {
  content: $icon-facebook;
}

.icon-for-family:before {
  content: $icon-for-family;
}

.icon-free-wi-fi:before {
  content: $icon-free-wi-fi;
}

.icon-garbage:before {
  content: $icon-garbage;
}

.icon-google-plus:before {
  content: $icon-google-plus;
}

.icon-hair-dryer:before {
  content: $icon-hair-dryer;
}
.icon-iron:before {
  content: $icon-iron;
}

.icon-mini-bar:before {
  content: $icon-mini-bar;
}

.icon-minus::before{
  content: $icon-minus;
}

.icon-my-account:before {
  content: $icon-my-account;
}

.icon-my-details:before {
  content: $icon-my-details;
}

.icon-my-orders:before {
  content: $icon-my-orders;
}

.icon-no-smoking:before {
  content: $icon-no-smoking;
}

.icon-notifications:before {
  content: $icon-notifications;
}

.icon-notice:before {
  content: $icon-notice;
}

.icon-payment-methods:before {
  content: $icon-payment-methods;
}

.icon-person:before {
  content: $icon-person;
}

.icon-person-outline:before {
  content: $icon-person-outline;
}

.icon-person-bold:before {
  content: $icon-person-bold;
}

.icon-pets-friendly:before {
  content: $icon-pets-friendly;
}

.icon-plus:before {
  content: $icon-plus;
}

.icon-shampoo:before {
  content: $icon-shampoo;
}

.icon-sign-out:before {
  content: $icon-sign-out;
}

.icon-slider-arrow-left:before {
  content: $icon-slider-arrow-left;
}

.icon-slider-arrow-right:before {
  content: $icon-slider-arrow-right;
}

.icon-success:before {
  content: $icon-success;
}

.icon-terms-conditions:before {
  content: $icon-terms-conditions;
}

.icon-spinner2:before {
	content: $icon-spinner2;
}

.icon-phone:before {
	content: $icon-phone;
}

.icon-mail:before {
	content: $icon-mail;
}

.icon-pricetags:before {
	content: $icon-pricetags;
}
.icon-eye:before {
  content: $icon-eye;
}
.icon-eye-blocked:before {
  content: $icon-eye-blocked;
}

.icon-heart:before {
  content: $icon-heart;
}

.icon-office:before {
	content: $icon-office;
}

.icon-sort-amount-asc:before {
  content: $icon-sort-amount-asc;
}

.icon-sort-amount-desc:before {
  content: $icon-sort-amount-desc;
}

.icon-bed:before {
  content: $icon-bed;
}

.icon-info:before {
  content: $icon-info;
}

//custom icons
.icon-custom-plus {
  display: block;
  position: relative;
  height: 0.75rem;
  width: 0.75rem;
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--v-primary-base);
  }
  &::before {
    height: 2px;
    width: 100%;
  }
  &::after {
    width: 2px;
    height: 100%;
  }
}

// custom spinner
@keyframes anim-rotate {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}
.small-spinner {
	display: inline-block;
	font-size: 1.1em;
	margin: 0 .5em;
	animation: anim-rotate 2s infinite linear;
	color: $color_light;
	text-shadow: 0 0 .25em rgba(255,255,255, .3);
}
