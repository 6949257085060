
.default-image {
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  &.low-scaled img {
    transform: scale(0.8);
  }
}
