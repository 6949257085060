
p {
  white-space: pre-wrap;
  line-height: 1.5rem;

  &.cancellation-policy {
    &-nonRefundable {
      color: var(--v-error-base);
    }
    &-free {
      color: var(--v-success-base);
    }
  }
}

.theme--light p.cancellation-policy-free {
  color: var(--v-success-darken2);
}
