.footer-links {
  text-align: right;
  padding: 0.93rem;

  ul {
    margin: 0;

    li {
      font-family: var(--hs-font-family-primary);
      font-size: 0.87rem;
      list-style-type: none;
      display: inline;

      &:not(:first-child):before {
        content: "	|	";
      }

      a {
        @include transition;
        color: var(--hs-color-link);
        text-decoration: none;
        &:hover {
          opacity: $opacity_sub;
          text-decoration: underline;
        }
      }
    }
  }
}
