.swal2-container {
  .swal2-modal {
    font-family: var(--hs-font-family-primary);
    border-radius: 0;
    .swal2-html-container {
      font-family: inherit;
    }
    .swal2-actions {
      button {
        @include transition(opacity);
        padding: $indent-m;
        min-width: 100px;
        border-radius: 0;
        cursor: pointer;

        &:active {
          opacity: 1;
        }
        &.swal2-confirm {
          color: $color_light;
          background-color: var(--v-primary-base);
          border-color: var(--v-primary-base);
          -webkit-box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12);
          box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12);
          &:hover {
            background-image: linear-gradient(rgba(var(--v-primary-base) 0.1), rgba(var(--v-primary-base) 0.1));
          }
        }
        &.swal2-deny,
        &.swal2-cancel {
          color: var(--v-primary-base);
          background: transparent;
          border: 1px solid var(--v-primary-base);
          &:hover {
            background: transparent !important;
            opacity: $opacity_disabled;
          }
        }
      }
    }
  }  
}

// dark theme override
html.dark .swal2-modal {
  background: $theme-dark-bg_el;
  .swal2-title,
  .swal2-html-container {
    color: $theme-dark-color;
  }
}
