
@import '@/app/static/css/mixins/templates';

.icon-angle-down {
  @include transition(transform);
  transform: rotate(0deg);
}

.rotate-180 {
  transform: rotate(180deg);
}
