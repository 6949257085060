.flex-center {
  display: flex;
  align-items: center;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}