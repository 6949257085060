
@import '@/app/static/css/variables';
@import '@/app/static/css/mixins/templates';

input.field__input,
textarea.field__input {
  @include placeholder {
    opacity: $opacity_disabled;
  }

  &:read-only,
  &:disabled {
    cursor: not-allowed;
  }
}
