$_modal_background: $color_light;

.modal-mask {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: -70px;
  padding-bottom: 70px;
  transform: translateY(70px);
  z-index: 9998;
  background-color: transparentize($_modal_background, .1);
}

.modal-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: var(--hs-font-family-primary);
  width: 100%;
}

.modal-header {
  flex-shrink: 0;
  position: relative;
  color: var(--hs-text-color);
  padding: $indent-xs $indent-xl;
  display: flex;
  justify-content: space-between;

  p {
    font-size: var(--hs-font-size-title) !important;
    font-family: var(--hs-font-family-title);
  }

  @media(max-width: $screen__m - 1) {
    padding: $indent-xs;
  }
}

.modal-title {
  background: rgba(0, 0, 0, $opacity_main);
}

.modal-close {
  @include button-reset;
  font-size: var(--hs-font-size-title);
  font-family: var(--hs-font-family-title);
  cursor: pointer;
  @include transition(color);

  &:hover {
    color: var(--hs-text-color);
  }
}

@media(max-width: $screen__s - 1) {
  .modal-mask {
    background-color: $_modal_background;
  }
}
