
:deep(.v-btn--icon.v-size--small) {
  height: 18px;
  width: 18px;

  & .v-icon {
    font-size: 12px !important;
  }
}

:deep(.v-carousel__controls__item) {
  margin: 0;
}
