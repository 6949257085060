.module__info-line {
  justify-content: inherit;
  .datesearch-for-single-room {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .fieldset,
    .actions-toolbar {
      display: inline-block;
    }

    .fieldset {
      width: 50%;
      .field.date {
        margin-bottom: $indent-m;
      }
      .module__content {
        width: 100%;
        display: flex;
        .field__item {
          max-width: 150px;
          &.number_guests {
            width: 40%;
            label {
              cursor: default;
            }
            .label__title {
              p {
                margin-top: 5px;
              }
            }
          }
          .datepicker-input {
            padding: 0.4rem;
            font-size: $font-size_default;
          }
          .date__difference {
            display: none;
          }
        }
      }
    }
  }
  &.with-book-button {
    justify-content: space-between;

    .info-line__control {
      display: flex;
      align-items: center;

      .icon-back {
        font-size: $font-size_l;
        margin-right: 10px;
        text-decoration: none;
        color: inherit;
      }
    }
    .guests {
      max-width: 250px;
    }
    .actions-toolbar {
      .action {
        display: inline-block;
        margin-right: 10px;
        &.primary, &.secondary {
          button {
            padding: 0.5rem 0.9rem;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
    &>.actions-toolbar {
      width: auto;
    }
  }
}

@media (max-width: $screen__xl - 1) {
  .module__info-line {
    &.with-book-button {
      .actions-toolbar {
        .action.primary button {
          padding: 0.5rem 0.7rem;
          font-size: 0.7rem;
        }
        .action.secondary button {
          padding: 0.6rem;
          font-size: 0.6rem;
        }
      }
    }
  }
}

@media (max-width: $screen__l - 1) {
  .module__room .module__info-line {
    align-items: center;

    &.with-book-button {
      .field.date .field__item {
        width: 46%;
      }
      .actions-toolbar {
        &.back-link {
          flex: 1 1 12%;
          justify-content: flex-end;
        }
        .action:not(.secondary) {
          display: block;
          margin: 0;
        }
      }
      .form .fieldset .field:last-child {
        margin-bottom: 0;
      }
    }
    .datesearch-for-single-room {
      align-items: flex-end;
      justify-content: flex-start;
      .fieldset {
        .module__content {
          .field__item {
            .label__title {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .info-line {
      &__heading {
        line-height: 2.2rem;
      }
      &__item {
        &.dates {
          @include activebg;
          background: $color_light;
          height: auto;
          justify-content: center;
          box-sizing: content-box;
          border-radius: $form_border-radius;
          padding: $indent-xs $indent-xs $indent-xs $indent-l;
          margin: 0;

          .dates-text {
            display: flex;
            align-items: center;
          }
        }
        &.guests {
          flex: 1 1 30%;
        }

        &.dates, &.guests {
          padding: 0.6rem;
          font-size: $font-size_s;
        }
      }
    }
  }
}

@media (max-width: $screen__m - 1) {
  .module__room .module__info-line {
    .datesearch-for-single-room {
      width: 100%;
      .fieldset {
        width: 100%;
        .module__content {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
      }
      .field__item {
        width: 31%;
      }
      .actions-toolbar .action.secondary button {
        display: block;
        height: 100%;
        width: 100%;
      }
    }
    &.with-book-button {
      .actions-toolbar {
        display: flex;
        justify-content: space-between;
      }
    }

    .info-line {
      &__heading {
        flex: 1 1 60%;
        margin-bottom: 0;
      }
      &__item {
        &.dates {
          margin-bottom: 0.6rem;
        }
      }
    }
  }
}

@media(max-width: $screen__s - 1) {
  .module__info-line {
    align-items: center;

    &.with-book-button {
      .field.date {
        .module__content {
          width: 100%;
        }
        .field__item {
          width: 31%;
          max-width: 180px;

          &:last-child {
            margin-right: 0;
          }

          .datepicker-heading {
            top: 0;
          }
        }
      }
      .actions-toolbar:not(.back-link) {
        margin-right: 0;
        width: 100%;

        &.margin_actions {
          margin: 10px 0;
        }
        .action.primary,
        .action.secondary {
          display: inline-block;
          width: 48%;
        }
      }
    }
    .info-line {
      &__heading {
        text-align: left;
      }
    }
  }
}