
@import '@/app/static/css/variables';

.order-list-item {
  .cancellation-policy {
    white-space: pre-line;
  }

  &__btn-remove {
    min-width: 30px;
    height: 30px;
    position: absolute !important;
    top: 2rem;
    right: 1rem;
  }
}

.room-info {
  display: flex;
  padding: $indent-m 0;

  &__img {
    width: 10rem;
    height: 7rem;

    img.img-cover {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: $form_border-radius;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    .title {
      font-size: $font-size_l;
    }

    @media (max-width: $screen__s - 1) {
      .rate-plan {
        display: block;
      }
    }
  }

  &__price {
    .price {
      .price-count {
        font-size: $font-size_default;
        font-weight: $font-weight_light;
      }

      &__default,
      &__discount {
        line-height: 1em;
      }
    }
  }
}
