
@import '@/app/static/css/variables';

.v-card .v-badge {
  position: absolute;
  z-index: 1;
  top: 20px;
  left: -5px;

  &__badge {
    border-radius: 0;
    text-transform: uppercase;
  }
}

@media (max-width: $screen__s - 1) {
  .column-reverse {
    display: flex;
    flex-direction: column-reverse;

    .text-h6 {
      font-size: 1.125rem !important;
    }
  }

  .price-from {
    display: none;
  }
}
