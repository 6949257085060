/* component style */
.vue-slider {
  padding: 0 !important;
  padding-bottom: 10px !important;
  cursor: pointer;
}
.vue-slider-disabled {
  opacity: $opacity_disabled;
  cursor: not-allowed;
}

/* rail style */
.vue-slider-rail {
  background-color: var(--hs-color-btn-bg-light);
  border-radius: 15px;
}

/* process style */
.vue-slider-process {
  background-color: var(--hs-color-btn-bg-opaque);
  border-radius: 15px;
}

/* mark style */
.vue-slider-mark {
  z-index: 4;
}
.vue-slider-mark:first-child .vue-slider-mark-step, .vue-slider-mark:last-child .vue-slider-mark-step {
  display: none;
}
.vue-slider-mark-step {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.16);
}
.vue-slider-mark-label {
  font-size: 14px;
  white-space: nowrap;
}
/* dot style */
.vue-slider-dot-handle {
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: $color_light;
  box-sizing: border-box;
  @include box-shadow(0.5px, 0.5px, 2px, 1px, rgba(0, 0, 0, 0.32));
  border-color: var(--hs-color-primary);
}
.vue-slider-dot-handle-focus {
  box-shadow: 0px 0px 1px 2px var(--hs-color-primary);
}

.vue-slider-dot-handle-disabled {
  cursor: not-allowed;
  opacity: $opacity_disabled;
}

.vue-slider-dot-tooltip-inner {
  font-size: 14px;
  white-space: nowrap;
  padding: 2px 5px;
  min-width: 20px;
  text-align: center;
  color: var(--hs-text-color);
  border-radius: 5px;
  box-sizing: content-box;
  background-color: var(--hs-body-bg);
  border: 1px solid var(--hs-color-primary);
}
.vue-slider-dot-tooltip-inner::after {
  content: "";
  position: absolute;
}
.vue-slider-dot-tooltip-inner-top::after {
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  height: 0;
  width: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px;
  border-top-color: inherit;
}
.vue-slider-dot-tooltip-inner-bottom::after {
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  height: 0;
  width: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px;
  border-bottom-color: inherit;
}
.vue-slider-dot-tooltip-inner-left::after {
  left: 100%;
  top: 50%;
  transform: translate(0, -50%);
  height: 0;
  width: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px;
  border-left-color: inherit;
}
.vue-slider-dot-tooltip-inner-right::after {
  right: 100%;
  top: 50%;
  transform: translate(0, -50%);
  height: 0;
  width: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px;
  border-right-color: inherit;
}

.vue-slider-dot-tooltip-wrapper {
  opacity: 0;
  @include transition(all, 0.3s);
}
.vue-slider-dot-tooltip-wrapper-show {
  opacity: 1;
}
