@import "templates";

@mixin button-reset {
  border: none;
  outline: none;
  background: none;
  box-shadow: none;
  box-sizing: border-box;
  -webkit-appearance: none;
  &:focus {
    outline-color: transparent;
    outline-style: none;
    box-shadow: none;
  }
}

@mixin input-reset {
  background: none;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: none;
  border: none;
  outline: none;
  box-shadow: none;
  box-sizing: border-box;
  border-radius: 0;
  &[type=number]::-webkit-inner-spin-button,
  &[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@mixin text-input {
  @include input-reset;
  @include transition;
  transition-property: border-color, z-index, color;
  font-size: $font-size_m !important;
  font-family: var(--hs-font-family-primary);
  border: 1px solid rgba(0, 0, 0, 0.42);
  width: 100%;
  position: relative;
  padding: 16px $indent-s;
  z-index: 1;
  @include placeholder {
    @include transition(color);
    color: currentColor;
    opacity: 0.7;
  }
  &.is-active,
  &:hover,
  &:focus {
    @include transition;
    @include placeholder {
      @include transition(color);
      color: currentColor;
    }
    border-color: $color_main;
    z-index: 2;
  }
}
