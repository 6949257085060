// screen size breakpoints
$screen__xxxs: 320px; // iPhone 4-inch
$screen__xxs:  420px;
$screen__xs:   576px;
$screen__s:    600px;
$screen__m:    960px;
$screen__l:    1264px;
$screen__xl:   1200px;

// color
$color_light: #FFFFFF;
$color_fill-light: darken($color_light, 4%);
$color_fill-dark: darken($color_light, 10%);
$color_main: #2B2A2A;

// vuetify colors
$theme-light-color: rgba(0, 0, 0, 0.87);
$theme-light-color_active: rgba(0, 0, 0, 0.42);
$theme-dark-color: rgba(255, 255, 255, 1);
$theme-dark-color_active: rgba(255, 255, 255, 0.24);
$theme-dark-bg_body: #121212;
$theme-dark-bg_el: #1E1E1E;

// opacity
$opacity_main: 0.35;
$opacity_sub: 0.65;
$opacity_disabled: 0.5;

// font
$font-family_sans-serif: 'Open Sans HSB', sans-serif;
$font-weight_light: 300;
$font-weight_regular: 400;
$font-weight_semibold: 600;
$font-weight_bold: 700;
$font-size_default: 0.875rem;
$font-size_xxl: 1.75rem;
$font-size_xl: 1.375rem;
$font-size_l: 1.125rem;
$font-size_m: 1.0rem;
$font-size_sm: 0.8125rem;
$font-size_s: 0.75rem;
$font-size_xs: 0.6875rem;
$font-size_xxs: 0.625rem;
$font-size_xxxs: 0.5rem;

// indent
$section-indent: 3.125rem;
$text-indent: 0.1875rem;
$indent-xl: 2.5rem;
$indent-l: 1.875rem;
$indent-m: 1.25rem;
$indent-s: 0.9375rem;
$indent-xs: 0.625rem;
$indent-xxs: 0.3125rem;
$line-height_default: 1.4em;
$line-height_text: 2.2em;

// forms
$form_border-radius: 2px;
$button-inc-dec_size: 2.5rem;

// icon
$icon-size: 0.375rem;

// transition
$transition-time: .4s;
$transition-time_longer: $transition-time * 1.5;
$transition-timing-function: cubic-bezier(.3, .1, .3, 1);

$card-height: 300px;

:export {
  opacityDisabled: $opacity_disabled;
}
