
@import '@/app/static/css/variables';
@import '@/app/static/css/mixins/templates';

.modal-close_with-bg i {
  @include transition;
  background: rgba(0, 0, 0, $opacity_main);
  border-radius: 50%;

  &:hover {
    @include transition;
    background: rgba(0, 0, 0, $opacity_main - 0.05);
  }
}
