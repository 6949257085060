@import "variables";
@import "mixins/templates";

@mixin gradient($color) {
  background: linear-gradient(rgba($color, 0) 0%,
      rgba($color, 0) 90%,
      rgba($color, 1) 100%);

}

.text--secondary,
.room-info .options {
  color: currentColor;
  opacity: $opacity_sub;
}

.theme--light {
  .theme-color {
    @include transition;
    color: $theme-light-color;

    &:hover {
      @include transition;
      color: currentColor;
    }
  }

  .theme-container {
    @include transition;
    color: $theme-light-color;
    border: 1px solid $theme-light-color_active;

    &:hover,
    &.focused {
      @include transition;
      border-color: currentColor !important;
    }

    &.has-error {
      border: 2px solid var(--v-error-base) !important;
    }
  }

  .gradient-layer {
    @include gradient($color_light)
  }

  .module__aside-menu {
    background: $color_light;
  }

  @media (max-width: $screen__l - 1) {
    .toolbar {
      background: $color_light;
    }
  }
}

.theme--dark {
  .theme-color {
    @include transition;
    color: currentColor !important;
  }

  .theme-container {
    @include transition;
    color: currentColor;
    border: 1px solid $theme-dark-color_active !important;

    &:hover {
      @include transition;
      border-color: currentColor !important;
    }

    &.has-error {
      border: 2px solid var(--v-error-base);
    }
  }

  &.v-expansion-panels .v-expansion-panel,
  &.v-data-table {
    background-color: inherit !important;
  }

  // sweetalert dark mode
  &+.swal2-container {
    .swal2-modal {
      background: $theme-dark-bg_el;
      border: 1px solid $theme-dark-color_active;

      .swal2-title,
      .swal2-html-container {
        color: $color_light;
      }
    }
  }

  .gradient-layer {
    @include gradient($theme-dark-bg_el )
  }

  .dropdown-menu .block__content,
  .module__aside-menu {
    background: $theme-dark-bg_el;
  }

  .module__search-rooms {
    border: 1px solid $theme-dark-color_active;
  }

  @media (max-width: $screen__l - 1) {
    .toolbar {
      background: $theme-dark-bg_body;
    }
  }
}

// override vuetify fonts with customization [config] font
.v-application {

  div,
  h1,
  h2,
  p,
  pre,
  span {
    font-family: var(--hs-font-family-primary) !important;
    &.error--text,
    &.text-subtitle-1,
    &.text-subtitle-2,
    &.text-body-1,
    &.text-body-2,
    &.text-caption,
    &.text-disabled,
    &.text-h4,
    &.text-h5,
    &.text-h6,
    &.text-lg-h6,
    &.text-subtitle-1,
    &.v-list-item__title {
      font-family: var(--hs-font-family-primary) !important;
    }
  }

  &.theme--light,
  &.theme--dark {
    background-image: var(--hs-body-bg-img) !important;
    background-attachment: fixed !important;
    background-size: cover !important;
    background-repeat: no-repeat;
    background-position: center top;
  }

  &.theme--dark {
    a {
      color: var(--v-primary-lighten5) !important;
    }
  }
}

.no-theme {
  background: $color_light !important;
  border-color: $theme-light-color_active !important;
  color: $theme-light-color !important;

  &.v-input {
    fieldset {
      border-color: $theme-light-color_active;
    }

    &:hover fieldset {
      @include transition;
      border-color: $theme-light-color;
    }

    input,
    .v-label {
      color: $theme-light-color !important;
    }

    .v-icon {
      color: #0000008a;
    }
  }
}
