
@import '@/app/static/css/variables';

.page404 {
  height: 100vh;
  width: 100vw;
  font-family: var(--hs-font-family-primary);
}

.page404__img {
  max-height: 100px;
  max-width: 80vw;
}

.page404__text .error {
  opacity: 0.65;
  font-size: 2.5rem;
  font-weight: bold;
}

.page404__text .description {
  font-size: $font-size_m;
}
