// list
$_font-size_options: $font-size_xs;
$_font-size_title: $font-size_xl;
$_font-size_price-count: $font-size_l;
$_font-size_price-info: $_font-size_options;
$_font-size_price-old: $_font-size_options;
$_font-size_price-discount: $_font-size_price-count;
$_font-size_includes: $_font-size_options;

$color_red: #E33939;
$_color_message: $color_red;
$_font-size_message: $font-size_xs;

//room
$_font-size_options-room: $font-size_default;
$_font-size_title-room: $font-size_xxl;
$_font-size_price-count-room: $font-size_xs * 2;
$_font-size_price-info-room: $_font-size_options-room;
$_font-size_price-old-room: $_font-size_options-room;
$_font-size_price-discount-room: $_font-size_price-count-room;
$_font-size_includes-room: $_font-size_options-room;

//room actions
$_actions_indent: 1.5rem;

@mixin mid-dot {
  &::after {
    content: "\00B7";
    margin: 0 $text-indent;
  }
}

.font-discounted {
  color: $color_red;
}

.default-color {
  color: $color_main;
}

.font-upper {
  text-transform: uppercase;
}

.price-discount-icon {
  @include icon-rounded();
  background-color: darkseagreen;
  color: white;
  font-weight: 600;

  &.icon-pricetags:before {
    margin-left: -2px;
  }
}

.vertical-position {
  display: flex;
  flex-direction: column;
}

.room-info {
  font-family: var(--hs-font-family-primary);
  .options {
    line-height: $line-height_default;
    text-transform: uppercase;
    font-size: $_font-size_options;
    &:not(:last-child) {
      @inclue mid-dot();
    }
    &-wrapper {
      display: flex;
      flex-wrap: wrap;
      &_room {
        .options {
          font-size: $_font-size_options-room;
        }
      }
    }
    &-value {
      padding-right: $text-indent;
    }
    [class^="icon-"] {
      font-size: $font-size_m;
      &:not(:first-child) {
        margin-left: -2px;
      }
    }
  }
  .title {
    line-height: $line-height_default;
    font-size: var(--hs-font-size-title) !important;
    font-family: var(--hs-font-family-title) !important;
    font-weight: $font-weight_semibold !important;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &-wrapper {
      padding-top: $indent-xxs;
      &_room {
        padding-top: $indent-xs;
        .title {
          font-size: $_font-size_title-room;
        }
      }
    }
  }
  .price {
    line-height: $line-height_default;
    font-size: $_font-size_price-count;
    .price-info {
      display: inline-block;
    }
    &-box {
      .price__default {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
      }
    }
    &__default {
      text-align: left;
    }
    &__old {
      .price-count {
        font-size: $_font-size_price-discount;
        opacity: 0.4;
      }
      .strike-out {
        position: relative;
        &::before {
          content: " ";
          display: block;
          width: 100%;
          border-top: 1px solid;
          border-color: inherit;
          height: 12px;
          position: absolute;
          left: 0;
          top: 50%;
          right: 0;
          transform: rotate(-5deg);
        }
      }
    }
    &__discount {
      .price-count {
        font-size: $_font-size_price-discount;
        .separated-price {
          color: var(--v-error-darken1);
        }
      }
    }
    &-wrapper {
      padding-top: $indent-xxs;
      &_room {
        .price {
          font-size: $_font-size_price-count-room;
          &-info {
            font-size: $_font-size_price-info-room;
            display: inline-block;
          }
          &__old {
            .price-count {
              font-size: $_font-size_price-old-room;
            }
          }
          &__discount {
            .price-count {
              font-size: $_font-size_price-discount-room;
            }
          }
        }
      }
    }
  }

  .includes {
    line-height: $line-height_default;
    font-size: $_font-size_includes;
    font-weight: $font-weight_semibold;
    &-title {
      padding-right: $text-indent;
    }
    &-value {
      text-transform: capitalize;
      &:not(:last-child) {
        &::after {
          content: ',';
          margin-right: $text-indent;
        }
      }
    }
    &-wrapper {
      padding-top: $indent-xxs;
      &_room {
        padding-top: $indent-xs;
        .includes {
          font-size: $_font-size_includes-room;
        }
      }
    }
  }
  .messages {
    &-wrapper {
      padding: $indent-xs 0;
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
    }
    &__message {
      color: $_color_message;
      font-size: $_font-size_message;
      text-transform: uppercase;
      line-height: $line-height_default;
      &:not(:last-child) {
        @include mid-dot();
      }
    }
  }
  .room {
    &__info-block {
      padding: $indent-xl 0 $indent-l;
      position: relative;
      &::after{
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 1px;
        background: var(--hs-border-color);
      }
      &.block-facilities {
        padding: $indent-xl 0 ($indent-l - $indent-m);
      }
      &.block-description{
        .block__content{
          .images{
            img{
              height: 100px;
              width: 150px;
              object-fit: cover;
              margin-right: $indent-s;
              margin-bottom: $indent-s;
            }
          }
        }
      }
      .block {
        &__title {
          margin-bottom: $indent-l;
        }
        &__content{
          overflow: hidden;
        }
      }
      &.block-packages{
        .item{
          .price-info{
            display: none;
          }
          .price-count {
            font-size: $font-size_default;
          }
          .price__old .price-count{
            font-size: $_font-size_price-old;
          }
        }
      }
    }
    &__actions-block {
      padding: $_actions_indent 0;
      display: flex;
      justify-content: center;

      .btn-book-now {
        width: 50%;
      }
    }
  }
}

@media (max-width: $screen__l - 1) {
  .room-info {
    .price {
      line-height: 1em;
    }
    .room__info-block {
      .items__list {
        .item {
          flex: 0 0 25%;
        }
      }
    }
  }
  .module__room {
    .room__main-info,
    .room__booking-info {
      flex-basis: 100%;
      flex-grow: 1;
    }
    .room__booking-info {
      padding-top: $indent-s;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      flex-wrap: wrap;
      .price-wrapper {
        flex-grow: 1;
      }
    }
    .includes-wrapper_room{
      flex-basis: 100%;
    }
  }
}


@media(max-width: $screen__m - 1) {
  .room-info .room__actions-block .btn-book-now {
    width: 100%;
  }
}

@media(max-width: $screen__s - 1) {
  // room-info
  .room-info {
    .price {
      line-height: $line-height_default;
    }
    .options-wrapper_room .options {
      font-size: $font-size_xs;
    }
    .title-wrapper_room {
      padding-top: $indent-xxs;
      .title {
        font-size: $font-size_l;
      }
    }
    .room__info-block {
      padding: $indent-s 0 $indent-xs;
      .block__title {
        margin-bottom: $indent-m;
        .title {
          position: relative;
          &::after {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate3d(0, -50%, 0);
            font-family: $icon-font;
            font-size: $icon-size;
            content: $icon-angle-down;
            @include transition(transform);
          }
        }
      }
      &.block-facilities {
        padding: $indent-s 0 ($indent-m - $indent-s);
      }
      &.block-packages {
        .title {
          &::after {
            display: none;
          }
        }
      }
      .items__list {
        .item {
          font-size: $font-size_default;
        }
      }
      &::after {
        @include transition(transform, $transition-time*1.3, $transition-timing-function, $transition-time);
        transform: translateZ(0) scaleX(0);
        transform-origin: 100% 0;
      }
      &.is-active {
        &::after {
          transform: translateZ(0) scaleX(1);
          transform-origin: 0 0;
        }
        .title::after {
          transform: translate3d(0, -50%, 0) rotate(180deg);
        }
      }
    }
  }
  .module__room {
    .modal-mask {
      .modal-container {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
      .modal-body {
        margin: auto;
      }
      .image__container {
        height: inherit;
      }
    }
    .room__booking-info {
      padding-top: 0;
      flex-wrap: wrap;
      .price-wrapper_room {
        flex-basis: 100%;
        flex-shrink: 0;
        .price,
        .price-discount {
          font-size: $font-size_default;
          .price-count {
            font-size: $font-size_default;
          }
          .price-info {
            font-size: $font-size_xs;
          }
        }
        .price__old {
          .price-count {
            font-size: $font-size_xs;
          }
        }
      }
      .includes-wrapper_room {
        .includes {
          font-size: $font-size_xs;
        }
      }
      .actions-toolbar {
        display: none;
      }
    }
    .room__grand-total {
      margin-top: $indent-s;
      padding: $indent-xxs 0 $indent-xs;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        width: 100vw;
        height: 1px;
        background-color: transparentize($color_fill-dark, $opacity_sub);
      }
      .price-wrapper_room {
        padding: 0;
        .price-box {
          justify-content: center;
          .price-count {
            font-size: $font-size_default;
          }
          .price-info {
            font-size: $font-size_xs;
          }
          .price__old {
            .price-count {
              font-size: $font-size_xs;
            }
          }
        }
      }

    }
    .room__actions-block {
      padding: 0 0 $indent-s;
    }
  }
}

@media (max-width: $screen__xs - 1) {
  .room-info .room__info-block {
    &.block-description .block__content .images img {
      width: 100%;
      height: 200px;
      margin-right: 0;
    }
  }
}

