
@import '@/app/static/css/variables';

.theme--light .shine {
  background: $color_light;
}

.theme--dark .shine {
  background: $theme-dark-bg_body;
}

.shine {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;

  &_main {
    transition:
      opacity 0.4s,
      visibility 0s 0.4s;

    &.shine--hidden {
      opacity: 0;
      visibility: hidden;
    }
  }

  &__custom-logo {
    width: 120px;
    height: 120px;
    margin-bottom: 45px;

    img {
      width: 100%;
    }
  }

  &__logo {
    height: 120px;
    width: 120px;
    margin-bottom: 45px;

    .logo-2 {
      opacity: 0;
      animation: loaded 1s infinite alternate cubic-bezier(0.3, 0.1, 0.3, 1);
    }
  }

  &__loading {
    height: 1px;
    width: 200px;
    position: relative;

    &::after,
    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }

    &::before {
      background: linear-gradient(to right, #fb8664, #f9cd45);
      transform-origin: 0 50%;
      transform: scaleX(0);
      animation: loading 1s;
    }

    &::after {
      background: linear-gradient(to right, #f9cd45, #fb8664);
      opacity: 0;
    }
  }
}

.shine__loading.ready::before {
  transform: scaleX(1);
}

.shine__loading.ready::after {
  animation: loaded 1s infinite alternate cubic-bezier(0.3, 0.1, 0.3, 1);
}

@keyframes loading {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

@keyframes loaded {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
