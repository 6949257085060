$_menu-transition-time: .4s;
$_menu-transition-timing-function: $transition-timing-function;

.customer-account__content-wrapper{
  .module__ads{
    will-change: transform;
    transition: transform, opacity;
    transition-duration: $_menu-transition-time;
    transition-timing-function: $_menu-transition-timing-function;
  }
  .module__menu-content{
    transition-duration: $_menu-transition-time;

    .menu-content{
      &__heading{
        will-change: transform;
        transition: transform, opacity;
        transition-duration: $_menu-transition-time;
        transition-timing-function: $_menu-transition-timing-function;
        transition-delay: .1s;

      }
      &__container{
        will-change: transform;
        transition: transform, opacity;
        transition-duration: $_menu-transition-time;
        transition-timing-function: $_menu-transition-timing-function;
      }
    }
  }
  .menu-enter-active{
    &.module__ads{
      transform: translate3d(0, -50px, 0);
      opacity: 0;
    }
    .menu-content{
      &__container,
      &__heading{
        opacity: 0;
        transform: translate3d(0, -50px, 0);
      }
    }
  }

  .menu-leave-active{
    &.module__ads{
      transform: translate3d(0, 50px, 0);
      opacity: 0;
    }
    .menu-content{
      &__heading{
        //transition-duration: $_menu-transition-time * 2;
      }
      &__container,
      &__heading{
        opacity: 0;
        transform: translate3d(0, 50px, 0);
      }
    }
  }
}