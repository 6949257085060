
@import '@/app/static/css/variables';
@import '@/app/static/css/mixins/all';

$dimensions: 40px;

.input-quantity {
  input {
    @include text-input;
    border: none !important;
    padding: 0 !important;
    margin: 0 $indent-xs;
    width: $dimensions - 15 !important;
    height: $dimensions;
    font-size: $font-size_m;
    text-align: center;
  }

  &--disabled {
    opacity: $opacity_disabled;
    cursor: default !important;
    &.primary--text:hover {
      opacity: $opacity_disabled;
    }
    &.secondary--text:hover::before {
      background: transparent;
    }
  }

  @media (max-width: $screen__xxs - 1) {
    @include clearfix;
    float: right;
  }
}

.theme--light,
.theme--dark {
  .input-quantity {
    .v-text-field > .v-input__control {
      min-height: unset;
      & > .v-input__slot {
        background: unset;
        padding: 0 !important;
      }
    }
  }
}
