
@import '@/app/static/css/variables';

::v-deep(.fa-ul) {
  font-size: 0.8rem;
}

@media (max-width: $screen__s - 1) {
  .rate-row {
    flex-direction: column;

    .v-divider {
      display: none;
    }
  }
}
