@mixin transition-input {
  @include transition(all, 0.3s, ease-in-out);
}

.file-image {
  display: flex;
  input {
    display: none !important;
  }

  &__img {
    width: 75px;
    height: 75px;
    display: block;
    background: darken($color_light, 3%);
    flex: none;

    img {
      width: 75px;
      height: 75px;
      object-fit: contain;
    }
  }

  &__img-placeholder {
    width: 100%;
    height: 100%;
    background: rgba(gray, 0.1);
    position: relative;
  }

  &__tool {
    margin-left: 0.6rem;
    width: 100%;
  }

  &__btn {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 30px;
    text-align: center;
    border: 1px solid $color_main;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    cursor: pointer;
    margin-bottom: 0.6rem;
    font-size: 0.75rem;
    white-space: nowrap;
  }

  &__description {
    span {
      display: block;
    }
  }
}
.vc-slider {
  width: 100%;
}
.vc-hue {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.vc-slider-swatches {
  display: none !important;
}
/* color picker */
.colorpicker {
  position: relative;

  &__row {
    display: flex;
    justify-content: space-between;
  }

  &__wrap {
    width: 220px;
    display: flex;
    align-items: center;
    .field {
      margin-bottom: 0 !important;
      input {
        text-transform: uppercase;
      }
    }
  }

  &__bar {
    background: linear-gradient(to right, #f00 0%, #ff0 17%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 83%, #f00 100%);
    height: 14px;
    width: 100%;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    cursor: pointer;
  }

  &__color {
    border: 1px solid #E4E4E4;
    cursor: pointer;
    height: 24px;
    width: 100%;
    flex: none;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
  }
  &__picker {
    padding: 3px 6px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 32px;
    margin-left: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .current-color {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #000;
    cursor: pointer;
  }

  .vc-chrome {
    position: absolute;
    top: 35px;
    z-index: 9;
  }

  .field {
    width: calc(60% - 0.3rem);

  }
}

/* color picker END*/

.radio-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  label {
    @include transition-input;
    border: 1px solid #E4E4E4;
    padding: 0.6rem;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    text-align: center;
    min-width: 30%;
    width: auto;
    cursor: pointer;

    &.active {
      color: var(--v-primary-base);
      border: 1px solid var(--v-primary-base);
    }

  }
  input {
    display: none;
  }
}

.switcher-field {
  position: relative;
  height: 30px;
  width: 60px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  background: $color_main;
  display: block;
  cursor: pointer;
  @include transition-input;

  &::after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    height: 26px;
    width: 26px;
    background: $color_light;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    @include transition-input;
  }

  &::before {
    content: '';
    position: absolute;
    height: 26px;
    width: 26px;
    background: transparent;
    z-index: 1;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    @include transition-input;
    top: 2px;
    right: 2px;
  }

  &.isActive {
    background: #FB9750;

    &::before {
      right: 6px;
    }

    &::after {
      left: 32px;
      @include transition-input;
    }
  }

  input {
    display: none;
  }
}
