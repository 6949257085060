.module__checkout {
  margin-top: 2.8rem;
  margin-bottom: 2.8rem;
  .total {
    line-height: $line-height_default !important;
    &.grand-total {
      .title {
        text-transform: uppercase;
      }
      .count {
        font-weight: $font-weight_semibold;
      }
    }
    &_room {
      .title__price {
        &::after {
          content: 'x';
          margin: 0 $indent-xxs;
        }
      }
    }
    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: $indent-xs;
      .title {
        flex-basis: 70%;
      }
      &.discount-info {
        margin-top: -0.625rem;
      }
    }
  }
}

.checkout,
.reservation-page {
  &__block {
    .block__loader {
      height: 150px;
      transform: translate(0, 0);
    }
    &.block-services {
      .module__info-line {
        flex-wrap: wrap;
        .info-line {
          &__heading,
          &__link {
            order: -1;
          }
          &__item {
            width: 100%;
            margin-right: 0;
          }
        }
        .info-line__heading, .back-link {
          margin-bottom: $indent-xs;
        }
      }
    }
    &.block-services {
      font-family: var(--hs-font-family-primary);
      .block {
        &__title {
          margin-bottom: $indent-m;
        }
        &__content {
          padding-bottom: $indent-xl;
          margin-bottom: $indent-m;
          .content-inner {
            padding-right: 0;
          }
        }
        &__subtotal {
          .price-box {
            display: inline-block;
          }
          .total_services-default{
            .count{
              opacity: $opacity_sub;
            }
          }
        }
      }
      .item {
        cursor: pointer;
        &__heading {
          display: flex;
          align-items: center;
        }
        &:not(:last-child) {
          margin-bottom: $indent-m;
        }
        &__title,
        &__price {
          font-size: $font-size_default;
        }
        &__title {
          cursor: pointer;
          overflow: hidden;
          p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: $indent-xxs;
            line-height: $line-height_default;
          }
        }
        &__price {
          margin-left: auto;
          white-space: nowrap;
          .price-box {
            display: flex;
          }
          .price-info {
            @include visually-hidden();
          }
          .price {
            &__discount {
              .price-count {
                color: var(--v-error-darken1);
              }
            }
          }
        }
        &__button {
          button {
            padding: $indent-xs $indent-xs;
            font-size: $font-size_default;
            font-size: var(--hs-font-size-btn);
            cursor: pointer;
            white-space: nowrap;
            @include transition;
            transition-property: background-color, color;
          }
        }
      }
      .block-services__btn-add-room,
      .block-services__btn-contact-details {
        width: 100%;
        padding: $indent-s
      }
    }
    &.block-thank-you {
      font-family: var(--hs-font-family-primary);
      display: flex;
      flex-direction: column;
      align-items: center;
      .thank-you {
        &__heading {
          margin: 3.4rem 0 $indent-l;
          p {
            font-size: var(--hs-font-size-title);
            font-family: var(--hs-font-family-title);
            line-height: 1.8em;
            text-align: center;
          }
        }
        &__body {
          // should be removed (moved styles to OrderList)
          .order-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: $indent-l;
            border-radius: $form_border-radius;
            .info {
              &__icon {
                margin-bottom: $indent-xs;
                i {
                  font-size: $font-size_m;
                }
              }
              &__number {
                margin-bottom: $indent-m;
                display: flex;
                flex-direction: column;
                align-items: center;
                .title {
                  font-weight: $font-weight_light;
                  font-size: $font-size_m;
                  line-height: $line-height_default;
                }
                .count {
                  font-size: var(--hs-font-size-title);
                  font-family: var(--hs-font-family-title);
                  line-height: $line-height_default;
                  letter-spacing: 3px;
                }
              }
              &__link {
                font-size: $font-size_default;
                font-weight: $font-weight_light;
                a {
                  &:hover {
                    text-decoration: none;
                  }
                }
              }
            }

          }
          .order-receipt {
            &__item {
              .title {
                font-weight: $font-weight_light;
                font-size: $font-size_m;
                line-height: $line-height_default;
                padding-right: 1.25rem;
              }
              .field {
                font-size: var(--hs-font-size-title);
                font-family: var(--hs-font-family-title);
                letter-spacing: 3px;
                line-height: $line-height_default;
              }
            }
            padding: 1.8rem;
          }
        }
        &__footer {
          text-align: center;
          .confirm-message {
            margin-bottom: 20px;
            font-weight: $font-weight_light;
            font-size: $font-size_default;
            line-height: $line-height_default;
          }
          .actions-toolbar{
            margin-bottom: 3.1rem;
          }
        }

      }
    }

  }
}

.form-checkout {
  font-family: var(--hs-font-family-primary);
  .form {
    &__heading {
      display: flex;
      align-items: center;
      margin-bottom: $indent-l;
      p, img {
        margin-right: $indent-xxs;
      }
      p {
        font-size: $font-size_default;
      }
      img {
        max-width: 2.5rem;
      }
    }
    &__paymentcard_logos {
      flex-flow: row wrap;
    }
    &__message {
        text-align: center;
        font-weight: 600;
        margin-bottom: $indent-l;
        p {
            font-size: $font-size_default;
            margin-right: $indent-xxs;
        }
      }
    &__body {
      .field {
        &__row {
          position: relative;
          display: flex;
          justify-content: flex-start;
          .field {
            flex-basis: 40%;
            &__item {
              width: 100%;
            }
          }

          .cvv {
            &-block {
              display: flex;
              justify-content: flex-end;
              align-items: flex-start;
              margin-bottom: $indent-s;
              .field {
                flex-basis: 62%;
                margin-bottom: 0;
              }
            }
            &-img {
              margin-right: 0.5rem;
            }
          }
        }
      }
      .row_label {
        font-size: 0.875rem;
        margin-bottom: 0.375rem;
      }
    }
    &__footer {
      .total {
        &.row-total {
          .title {
            &__text {
              margin-right: $text-indent;
            }
          }
          .count {
            font-weight: $font-weight_semibold;
          }
        }
      }
      &.security-icons {
        text-align: center;
        margin-top: $indent-m;
      }
      .privacy-policy {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: $font-size_s;
        label {
          margin: 0 5px;
          line-height: initial;
        }
        a {
          color: var(--v-primary-base);
          &:hover {
            opacity: $opacity_sub;
            text-decoration: none;
          }
        }
      }
      button {
        min-width: 100%;
        padding: $indent-s;
      }
    }
  }
}

.error-row {
  margin-bottom: 0.625rem;
}

.guest-info-wrapper {
  margin-top: $indent-xs;
  font-size: $font-size_xxs;
}

.date-range {
  margin-top: $indent-xs;
  font-size: $font-size_xxs;
}

@media (max-width: $screen__s - 1) {
  .checkout__block {
    &.block-thank-you {
      padding: $indent-m $indent-xs;
    }
  }
}

@media (max-width: $screen__s - 1) {
  .module__checkout {
    margin-top: 0;
    margin-bottom: 0;
    overflow-x: hidden;
    .header-wrapper {
      margin-right: -$indent-s;
      margin-left: -$indent-s;
      .header__logo a {
        padding: $indent-m 0;
      }
      .action.close {
        left: $indent-m + $indent-xxs;
      }
    }
  }
  .checkout__block {
    &.block-services {
      .block {
        &__title {
          h2 {
            text-transform: none;
          }
        }
        &__content {
          border-bottom: none;
          @include fullwidthline(bottom);
          .content-inner {
            padding-left: 0;
            padding-bottom: 0;
          }
        }
        &__subtotal {
          .total:last-child {
            margin-bottom: $indent-m;
          }
        }
        &__footer {
          .total.grand-total {
            padding-top: $indent-s;
            @include fullwidthline();
            justify-content: center;
            .title {
              margin-right: $indent-xxs;
              text-transform: none;
            }
            .count {
              font-weight: $font-weight_regular;
            }
          }
        }
      }
    }
    &.block-thank-you {
      .thank-you {
        &__heading {
          margin: $indent-m 0 $indent-l;
        }
        &__body {
          padding: 0 $indent-m;
          margin-bottom: 8.1rem;
          width: 100%;
          .order-info {
            width: 100%;
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .form.scale-label {
    .fieldset .field {
      margin-bottom: 2rem;
    }
  }
  .form,
  .form__footer,
  .form.scale-label.form-guest-info {
    .actions-toolbar.fixed-toolbar {
      @include toolbar;
      .block-services__btn-contact-details {
        width: 87vw;
        margin: auto;
      }
    }
  }
}

.datatrans__block {
  position: relative;

  &__content {
    @include transition(opacity, 1s, ease-out);
  }

  .block__loader {
    position: absolute;
    top: 50%;
    width: 100%;
  }
}

.non-visible {
  @include transition(opacity, 1s, ease);
  opacity: 0;
  visibility: hidden;
}

@media (max-width: $screen__xs - 1) {
  .form-checkout .form__footer.security-icons {
    margin-bottom: 3.1rem;
  }
}
