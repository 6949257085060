$_accordion-item_icon-size: 2.5rem;
$_accordion-item_font-size: $font-size_default;

.block__accordion {
  display: flex;
  flex-direction: column;
  .item {
    cursor: pointer;
    &__heading {
      @include flex-center;
    }
    &.is-default {
      .item__button {
        opacity: 0.8;
      }
    }
    &__description {
      padding-left: $_accordion-item_icon-size + $indent-m;
      overflow: hidden;
      strong {
        padding-top: $indent-s;
        font-size: $font-size_default;
        line-height: $line-height_default;
        display: block;
        text-align: center;
      }
      .content-inner {
        position: relative;
        padding: $indent-s 0;
        .images {
          img {
            height: 166px;
            width: 250px;
            object-fit: cover;
          }
        }
        .description p {
          font-weight: inherit;
        }
        .text p {
          span.text__price--discount {
            padding-left: $indent-xxs;
            color: var(--v-error-darken1);
          }
        }
      }
      p {
        font-size: $font-size_sm;
      }
    }
    &__images {
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      position: relative;
      margin: $indent-xxs 0;
      width: 100% !important;
      .tns-controls button {
        &::before {
          background-color: transparent !important;
        }
        &::after {
          background-color: transparent !important;
        }
      }
      .image__container {
        height: auto !important;
        &:not(.tns-item):not(.single) {
          position: absolute;
          top: 0;
          left: 0;
          margin: $indent-m 0;
        }
        &.is-active,
        &:first-child {
          z-index: 1;
        }
        display: inline-block;
        &.single {
          width: 50%;
          img {
            max-width: 100%;
            height: auto;
          }
        }
      }
      .modal-mask {
        .modal-body {
          flex-grow: 1;
          height: 0;
        }
        .image__container {
          height: calc(100vh - 100px) !important;
          width: 100%;
          img {
            cursor: auto;
          }
          img.img-cover {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
        .modal-slider {
          position: relative;
        }
      }
    }
    &:not(:last-child) {
      margin-bottom: $indent-m;
    }
    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      height: $_accordion-item_icon-size;
      width: $_accordion-item_icon-size;
      font-size: $icon-size;
      cursor: pointer;
      @include transition(border-color);
      i {
        @include transition(
          transform,
          $transition-time_longer,
          $transition-timing-function
        );
      }
    }
    &__info {
      padding: 0 $indent-s;
      max-width: 100%;
      overflow: hidden;
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__title,
    &__price {
      font-size: 1em;
    }
    &__title {
      cursor: pointer;
    }
    &__button {
      button {
        font-family: var(--hs-font-family-primary);
        font-size: $font-size_default;
        padding: $indent-xs;
        width: 125px;
        @include transition;
        transition-property: color, background-color;
        cursor: pointer;
      }
      .service-selection-button {
        @include clearfix;
        float: right;
        &--unselected i::after {
          @include transition;
          transition-duration: $transition-time_longer;
        }

        @media (max-width: $screen__s - 1) {
          .value {
            @include visually-hidden;
          }
          .overlay {
            display: none;
          }
        }
      }
    }
    &.is-active {
      .item__description {
        transform: none;
      }
      .item__icon {
        i {
          transform: rotate(180deg);
        }
      }
    }
    &.is-default {
      .item__price {
        opacity: $opacity_sub;
      }
      .item__button {
        width: auto;
        pointer-events: none;
      }
    }
  }
}

@media (max-width: $screen__s - 1) {
  .block__accordion {
    .item {
      &__icon {
        height: calc($icon-size * 2);
        width: calc($icon-size * 2);
        margin-right: $indent-xxs;
      }
      &__info {
        flex-wrap: wrap;
        padding-left: $indent-xs;
      }
      &__title {
        overflow: hidden;
        line-height: $line-height_default;
        p {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      &__price {
        line-height: 1;
        .price-count {
          font-size: $font-size_s;
        }
        p {
          white-space: nowrap;
        }
      }
      &__button button {
        @include flex-center;
        width: $_accordion-item_icon-size;
        height: $_accordion-item_icon-size;
        padding: 0;
        i {
          &::after,
          &::before {
            @include transition;
            transition-property: opacity, background-color, transform;
            transition-duration: $transition-time_longer;
          }
          .value {
            @include visually-hidden;
          }
          .overlay {
            display: none;
          }
        }
        .overlay {
          display: none;
        }
        &:hover .value {
          transform: none;
        }
      }
      &__description {
        padding-left: $icon-size * 2 + $indent-s;
        p {
          padding-top: 0;
          font-size: $font-size_s;
        }
        .content-inner {
          padding-right: 0;
          .text {
            padding-right: $_accordion-item_icon-size + $indent-m;
          }
          .modal-mask {
            .modal-container {
              height: 100%;
              display: flex;
              flex-direction: column;
            }
            .modal-body {
              margin: auto;
            }
            .image__container {
              height: 80vh;
            }
          }
        }
      }
      &.is-included {
        .item__button .service-selection-button {
          i {
            &::before,
            &::after {
              background-color: var(--v-primary-base);
            }
            &::after {
              @include transition;
              transition-duration: $transition-time_longer;
              transform: translate(-50%, -50%) rotate(90deg);
              opacity: 0;
            }
          }
        }
      }
    }
  }
}

@media (max-width: $screen__xxs - 1) {
  .block__accordion {
    .item {
      &__images {
        max-width: 100%;
      }
      &__description {
        .content-inner {
          .images img {
            width: 100%;
          }
        }
      }
    }
  }
}
