
@import '@/app/static/css/variables';
@import '@/app/static/css/mixins/templates';

.order-list {
  font-family: var(--hs-font-family-primary);
  border-radius: $form_border-radius;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__header {
    position: relative;
    text-align: center;
    cursor: pointer;

    &::after,
    &::before {
      content: '';
      position: absolute;
      height: 1px;
      left: 0;
      right: 0;
      background: currentColor;
      @include transition(
        transform,
        $transition-time * 1.4,
        $transition-timing-function
      );
      transform: scaleX(0);
    }

    &::before {
      top: -1px;
      transform-origin: 100% 100%;
    }

    &::after {
      bottom: -1px;
      transform-origin: 0 0;
    }

    i {
      display: inline-block;
      @include transition(
        transform,
        $transition-time * 1.4,
        $transition-timing-function
      );
      font-size: $icon-size;
      margin-left: $indent-xxs;
    }
  }

  &__body {
    overflow: hidden;

    .info {
      &__icon {
        i {
          font-size: $font-size_m;
        }
      }

      &__link {
        font-size: $font-size_default;
        font-weight: $font-weight_light;

        a {
          color: var(--hs-color-link);

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }

  &:not(.is-active) {
    .order-list__header {
      i {
        animation: arrow-end $transition-time * 1.4 $transition-timing-function;
      }
    }
  }

  &.is-active {
    .order-list__header {
      &::before,
      &::after {
        transform: none;
      }

      &::before {
        transform-origin: 0 0;
      }

      &::after {
        transform-origin: 100% 100%;
      }

      i {
        animation: arrow-start $transition-time * 1.4
          $transition-timing-function;
        transform: rotate(180deg);
      }
    }
  }
}

@keyframes arrow-start {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(180deg);
  }
}

@keyframes arrow-end {
  0% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
