
@import '@/app/static/css/variables';

.room-facilities {
  display: grid;
  grid-auto-rows: 1fr;

  @media (min-width: $screen__s) {
    grid-template-columns: repeat(3, 1fr);
  }
}
