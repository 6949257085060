@media (max-width: $screen__l - 1) {
  .module__aside-menu {
    @include asideMenu;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 50%;
    margin-top: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    transform: translate3d(-100%, 0, 0);
    padding: 0;
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-direction: column;
    .module__content{
      padding: 0 $indent-m;
    }
    .module__heading {
      flex-shrink: 0;
      font-family: var(--hs-font-family-primary);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: $indent-m;
      width: 100%;
      position: sticky;
      top: 0;
      z-index: 1001;
      .module__title {
        font-size: $font-size_s;
      }
      .action {
        &__close {
          font-size: $font-size_default;
          cursor: pointer;
        }
        &__clear {
          button {
            @include button-reset;
            font-size: $font-size_s;
            opacity: $opacity_sub;
            cursor: pointer;
          }
        }
      }
    }
    .actions-toolbar {
      display: flex;
      justify-content: space-between;
      padding: $indent-m;
      z-index: 101;
      margin-top: auto;
      &.stick-to-bottom {
        position: sticky;
        bottom: 0;
      }
      .action {
        flex-basis: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        &:only-child {
          flex-basis: 100%;
        }
      }
    }
  }
}


@media(max-width: $screen__s - 1) {
  //aside menu
  .module__aside-menu {
    width: 100%;
    padding-top: 0;
  }
}
