
#conversion-notice {
  font-size: 0.875rem;
}


tr:first-child td,
tr.highlight td {
  font-size: 1em !important;
}

tr.highlight {
  font-weight: bold;
}
