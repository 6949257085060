
@import "@/app/static/css/variables";

.reservation {
  &-page {
    font-family: var(--hs-font-family-primary);
  }

  margin-bottom: $section-indent;

  .total {
    font-size: $font-size_default;
    line-height: $line-height_default;

    &.grand-total {
      .title {
        text-transform: uppercase;
      }

      .count {
        font-weight: $font-weight_semibold;
      }
    }

    &_room {
      .title__price {
        &::after {
          content: "x";
          margin: 0 $indent-xxs;
        }
      }
    }

    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: $indent-xs;

      .title {
        flex-basis: 70%;
      }

      &.discount-info {
        margin-top: -1rem;
      }
    }
  }
}
