
@import '@/app/static/css/variables';
@import '@/app/static/css/mixins/all';

.vc {
  &-container {
    font-family: var(--hs-font-family-primary);
    font-size: var(--text-xs);
    border-radius: 5px;
    position: relative;
  }

  &-date-picker__input {
    padding-top: 0.891rem !important;
    padding-bottom: 0.891rem !important;
  }

  &-popover {
    &-content {
      @include box-shadow(0, 2px, 4px, 0, rgba(0, 0, 0, 0.11));

      &-wrapper {
        .vc-day-popover-container {
          text-align: center;
          font-family: var(--hs-font-family-primary);
          font-size: var(--text-sm);
          border-color: var(--hs-color-btn-bg);

          .date-diff {
            margin-bottom: $indent-xxs;
          }
        }
      }
    }

    &-caret {
      z-index: 4;
    }
  }

  &-pane {
    padding: 10px;

    .vc-weeks {
      padding: 0;
    }
  }

  &-header {
    padding: 0 !important;
    margin-bottom: 12px;

    .vc-title {
      font-size: var(--text-xs);
      font-weight: var(--font-normal);
      font-family: var(--hs-font-family-primary);
      pointer-events: none !important;
      cursor: default;

      &:hover {
        opacity: 1;
      }
    }
  }

  &-arrow {
    @include transition;

    &:hover {
      opacity: $opacity_sub;
      background: unset !important;
    }
  }

  &-weeks {
    .vc-weekday,
    .vc-day {
      font-family: var(--hs-font-family-primary);
      height: 42px;

      @media (min-width: $screen__xxxs) {
        min-width: 42px;
      }

      &-content {
        color: inherit;

        &.is-disabled {
          color: inherit !important;
          opacity: 0.4;
          cursor: not-allowed;

          &:hover {
            background: unset !important;
            opacity: 0.4 !important;
          }
        }
      }
    }

    .vc-weekday {
      @include flex-center;
      font-weight: var(--font-semibold);
      font-size: 10px;
      opacity: $opacity_sub;
    }

    .vc-day {
      $color: var(--hs-color-btn);
      $bg-color: var(--v-primary-base);

      &.start-date {
        pointer-events: none;
        touch-action: none;
      }

      .vc-day-content {
        &:hover {
          color: $color;
          background: $bg-color !important;
          opacity: $opacity_sub;
        }

        &:focus {
          background-color: unset;
        }
      }

      .vc-highlights + .vc-day-content {
        color: var(--hs-color-btn) !important;
      }

      .vc-highlight {
        border-color: rgba($bg-color, 0.8) !important;
        background-color: $bg-color !important;

        &.vc-highlight-base-start,
        &.vc-highlight-base-middle,
        &.vc-highlight-base-end {
          opacity: $opacity_sub !important;
        }
      }
    }
  }
}

@media (max-width: $screen__l - 1) {
  .vc {
    &-container {
      border: none !important;
    }
  }
}

// override v-text-field paddings
// .field__control .label .v-text-field > .v-input__control > .v-input__slot {
//   padding: 0 !important;
//   .v-text-field__slot input {
//     padding: 25px 15px;
//   }
// }
