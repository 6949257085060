.text {
  p, pre {
    font-size: $font-size_m;
    line-height: $line-height_text;
    text-align: justify;
    white-space: pre-wrap;
  }
}
.title {
  h2 {
    font-size: $font-size_l;
    font-weight: $font-weight_semibold;
    line-height: $line-height_default;
  }
}

@media(max-width: $screen__s - 1) {
  // text
  .text p, .text pre {
    font-size: $font-size_default;
  }
  .title h2 {
    font-size: $font-size_default;
    font-weight: $font-weight_regular;
    text-transform: uppercase;
  }
}
