
//@include  font-face('font-name', 'font-url');


// google fonts

/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans HSB';
  font-style: normal;
  font-weight: 300;
  src: url(https://assets.hotel-spider.com/static/wbe/fonts/DXI1ORHCpsQm3Vp6mXoaTQ7aC6SjiAOpAWOKfJDfVRY.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Open Sans HSB';
  font-style: normal;
  font-weight: 300;
  src: url(https://assets.hotel-spider.com/static/wbe/fonts/DXI1ORHCpsQm3Vp6mXoaTRdwxCXfZpKo5kWAx_74bHs.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Open Sans HSB';
  font-style: normal;
  font-weight: 300;
  src: url(https://assets.hotel-spider.com/static/wbe/fonts/DXI1ORHCpsQm3Vp6mXoaTZ6vnaPZw6nYDxM4SVEMFKg.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Open Sans HSB';
  font-style: normal;
  font-weight: 300;
  src: url(https://assets.hotel-spider.com/static/wbe/fonts/DXI1ORHCpsQm3Vp6mXoaTfy1_HTwRwgtl1cPga3Fy3Y.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Open Sans HSB';
  font-style: normal;
  font-weight: 300;
  src: url(https://assets.hotel-spider.com/static/wbe/fonts/DXI1ORHCpsQm3Vp6mXoaTfgrLsWo7Jk1KvZser0olKY.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Open Sans HSB';
  font-style: normal;
  font-weight: 300;
  src: url(https://assets.hotel-spider.com/static/wbe/fonts/DXI1ORHCpsQm3Vp6mXoaTYjoYw3YTyktCCer_ilOlhE.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Open Sans HSB';
  font-style: normal;
  font-weight: 300;
  src: url(https://assets.hotel-spider.com/static/wbe/fonts/DXI1ORHCpsQm3Vp6mXoaTRampu5_7CjHW5spxoeN3Vs.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2212, U+2215;
}


/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans HSB';
  font-style: normal;
  font-weight: 400;
  src: url(https://assets.hotel-spider.com/static/wbe/fonts/K88pR3goAWT7BTt32Z01m4X0hVgzZQUfRDuZrPvH3D8.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Open Sans HSB';
  font-style: normal;
  font-weight: 400;
  src: url(https://assets.hotel-spider.com/static/wbe/fonts/RjgO7rYTmqiVp7vzi-Q5UYX0hVgzZQUfRDuZrPvH3D8.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Open Sans HSB';
  font-style: normal;
  font-weight: 400;
  src: url(https://assets.hotel-spider.com/static/wbe/fonts/LWCjsQkB6EMdfHrEVqA1KYX0hVgzZQUfRDuZrPvH3D8.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Open Sans HSB';
  font-style: normal;
  font-weight: 400;
  src: url(https://assets.hotel-spider.com/static/wbe/fonts/xozscpT2726on7jbcb_pAoX0hVgzZQUfRDuZrPvH3D8.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Open Sans HSB';
  font-style: normal;
  font-weight: 400;
  src: url(https://assets.hotel-spider.com/static/wbe/fonts/59ZRklaO5bWGqF5A9baEEYX0hVgzZQUfRDuZrPvH3D8.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Open Sans HSB';
  font-style: normal;
  font-weight: 400;
  src: url(https://assets.hotel-spider.com/static/wbe/fonts/u-WUoqrET9fUeobQW7jkRYX0hVgzZQUfRDuZrPvH3D8.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Open Sans HSB';
  font-style: normal;
  font-weight: 400;
  src: url(https://assets.hotel-spider.com/static/wbe/fonts/cJZKeOuBrn4kERxqtaUH3ZBw1xU1rKptJj_0jans920.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2212, U+2215;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans HSB';
  font-style: normal;
  font-weight: 600;
  src: url(https://assets.hotel-spider.com/static/wbe/fonts/MTP_ySUJH_bn48VBG8sNSg7aC6SjiAOpAWOKfJDfVRY.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Open Sans HSB';
  font-style: normal;
  font-weight: 600;
  src: url(https://assets.hotel-spider.com/static/wbe/fonts/MTP_ySUJH_bn48VBG8sNShdwxCXfZpKo5kWAx_74bHs.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Open Sans HSB';
  font-style: normal;
  font-weight: 600;
  src: url(https://assets.hotel-spider.com/static/wbe/fonts/MTP_ySUJH_bn48VBG8sNSp6vnaPZw6nYDxM4SVEMFKg.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Open Sans HSB';
  font-style: normal;
  font-weight: 600;
  src: url(https://assets.hotel-spider.com/static/wbe/fonts/MTP_ySUJH_bn48VBG8sNSvy1_HTwRwgtl1cPga3Fy3Y.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Open Sans HSB';
  font-style: normal;
  font-weight: 600;
  src: url(https://assets.hotel-spider.com/static/wbe/fonts/MTP_ySUJH_bn48VBG8sNSvgrLsWo7Jk1KvZser0olKY.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Open Sans HSB';
  font-style: normal;
  font-weight: 600;
  src: url(https://assets.hotel-spider.com/static/wbe/fonts/MTP_ySUJH_bn48VBG8sNSojoYw3YTyktCCer_ilOlhE.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Open Sans HSB';
  font-style: normal;
  font-weight: 600;
  src: url(https://assets.hotel-spider.com/static/wbe/fonts/MTP_ySUJH_bn48VBG8sNShampu5_7CjHW5spxoeN3Vs.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2212, U+2215;
}

/* cyrillic */
@font-face {
  font-family: 'Playfair Display HSB';
  font-style: normal;
  font-weight: 400;
  src: url(https://assets.hotel-spider.com/static/wbe/fonts/2NBgzUtEeyB-Xtpr9bm1CRw5vVFbIG7DatP53f3SWfE.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Playfair Display HSB';
  font-style: normal;
  font-weight: 400;
  src: url(https://assets.hotel-spider.com/static/wbe/fonts/2NBgzUtEeyB-Xtpr9bm1CaH_fTF-WHdxjXJZkfhNjc4.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Playfair Display HSB';
  font-style: normal;
  font-weight: 400;
  src: url(https://assets.hotel-spider.com/static/wbe/fonts/2NBgzUtEeyB-Xtpr9bm1CSVudZg2I_9CBJalMPResNk.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Playfair Display HSB';
  font-style: normal;
  font-weight: 400;
  src: url(https://assets.hotel-spider.com/static/wbe/fonts/2NBgzUtEeyB-Xtpr9bm1CRD8Ne_KjP89kA3_zOrHj8E.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2212, U+2215;
}



// icomoon (icon-font)
@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon/icomoon.eot?jo9v6l');
  src:  url('../fonts/icomoon/icomoon.eot?jo9v6l#iefix') format('embedded-opentype'),
  url('../fonts/icomoon/icomoon.ttf?jo9v6l') format('truetype'),
  url('../fonts/icomoon/icomoon.woff?jo9v6l') format('woff'),
  url('../fonts/icomoon/icomoon.svg?jo9v6l#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Google fonts */

/* vietnamese */
@font-face {
  font-family: 'Bangers';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Bangers Regular'), local('Bangers-Regular'), url(https://assets.hotel-spider.com/static/wbe/fonts/FeVQS0BTqb0h60ACH5FQ2Ixi.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Bangers';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Bangers Regular'), local('Bangers-Regular'), url(https://assets.hotel-spider.com/static/wbe/fonts/FeVQS0BTqb0h60ACH5BQ2Ixi.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Bangers';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Bangers Regular'), local('Bangers-Regular'), url(https://assets.hotel-spider.com/static/wbe/fonts/FeVQS0BTqb0h60ACH55Q2A.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'Bungee';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Bungee'), local('Bungee-Regular'), url(https://assets.hotel-spider.com/static/wbe/fonts/N0bU2SZBIuF2PU_0AnR1Gd8.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Bungee';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Bungee'), local('Bungee-Regular'), url(https://assets.hotel-spider.com/static/wbe/fonts/N0bU2SZBIuF2PU_0A3R1Gd8.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Bungee';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Bungee'), local('Bungee-Regular'), url(https://assets.hotel-spider.com/static/wbe/fonts/N0bU2SZBIuF2PU_0DXR1.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Courier Prime';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Courier Prime Regular'), local('CourierPrime-Regular'), url(https://assets.hotel-spider.com/static/wbe/fonts/u-450q2lgwslOqpF_6gQ8kELaw9pWt_-.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Courier Prime';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Courier Prime Regular'), local('CourierPrime-Regular'), url(https://assets.hotel-spider.com/static/wbe/fonts/u-450q2lgwslOqpF_6gQ8kELawFpWg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'Gelasio';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Gelasio Regular'), local('Gelasio-Regular'), url(https://assets.hotel-spider.com/static/wbe/fonts/cIf9MaFfvUQxTTqS9CGhYVkH.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Gelasio';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Gelasio Regular'), local('Gelasio-Regular'), url(https://assets.hotel-spider.com/static/wbe/fonts/cIf9MaFfvUQxTTqS9CChYVkH.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Gelasio';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Gelasio Regular'), local('Gelasio-Regular'), url(https://assets.hotel-spider.com/static/wbe/fonts/cIf9MaFfvUQxTTqS9C6hYQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Gochi Hand';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Gochi Hand'), local('GochiHand-Regular'), url(https://assets.hotel-spider.com/static/wbe/fonts/hES06XlsOjtJsgCkx1Pkfon_-w.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Handlee';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Handlee Regular'), local('Handlee-Regular'), url(https://assets.hotel-spider.com/static/wbe/fonts/-F6xfjBsISg9aMakPm3wow.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Marck Script';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Marck Script'), local('MarckScript-Regular'), url(https://assets.hotel-spider.com/static/wbe/fonts/nwpTtK2oNgBA3Or78gapdwuyzCg_WMM.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Marck Script';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Marck Script'), local('MarckScript-Regular'), url(https://assets.hotel-spider.com/static/wbe/fonts/nwpTtK2oNgBA3Or78gapdwuyxig_WMM.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Marck Script';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Marck Script'), local('MarckScript-Regular'), url(https://assets.hotel-spider.com/static/wbe/fonts/nwpTtK2oNgBA3Or78gapdwuyyCg_.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Neucha';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Neucha'), url(https://assets.hotel-spider.com/static/wbe/fonts/q5uGsou0JOdh94bfuQltOxU.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin */
@font-face {
  font-family: 'Neucha';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Neucha'), url(https://assets.hotel-spider.com/static/wbe/fonts/q5uGsou0JOdh94bfvQlt.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'), url(https://assets.hotel-spider.com/static/wbe/fonts/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNa7lqDY.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'), url(https://assets.hotel-spider.com/static/wbe/fonts/6xK3dSBYKcSV-LCoeQqfX1RYOo3qPK7lqDY.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'), url(https://assets.hotel-spider.com/static/wbe/fonts/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNK7lqDY.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'), url(https://assets.hotel-spider.com/static/wbe/fonts/6xK3dSBYKcSV-LCoeQqfX1RYOo3qO67lqDY.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'), url(https://assets.hotel-spider.com/static/wbe/fonts/6xK3dSBYKcSV-LCoeQqfX1RYOo3qN67lqDY.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'), url(https://assets.hotel-spider.com/static/wbe/fonts/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNq7lqDY.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'), url(https://assets.hotel-spider.com/static/wbe/fonts/6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7l.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
