
@import '@/app/static/css/variables';

.module__info-line {
  .field__item.date__check-in,
  .field__item.date__check-out {
    input {
      font-size: $font-size_default;
    }
  }

  .field__item.date__check-in {
    margin-right: $indent-xxs;
  }

  .label__title i {
    margin-right: $indent-xs;
  }
}
