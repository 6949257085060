
@import '@/app/static/css/variables';
.success-header {
  text-align: center;
  margin-bottom: $indent-l;
  i {
    display: block;
    font-size: 5.8rem;
    margin-bottom: $indent-m;
    color: var(--v-success-base);
  }
  p {
    color: var(--v-success-base);
    font-weight: $font-weight_bold;
    font-size: 2.2rem;
  }
}
