@import "../variables";

@mixin visually-hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}

@mixin clearfix {
  &::after {
   content: "";
   display: block;
   clear: both;
  }
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin position-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin image-contain {
  position: relative;
  overflow: hidden;
  > img {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
  }
}

@mixin image-cover {
  @include image-contain;
  > img {
   min-width: 100%;
   min-height: 100%;
  }
}

@mixin image-fit-width {
  @include image-contain;
  > img {
   max-width: 100%;
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}
  &::placeholder               {@content}
}

@mixin selection ($color, $background) {
  ::selection {
    color: $color;
	  background: $background;
  }
}

@mixin box-shadow($x, $y, $blur, $spread, $color) {
  -webkit-box-shadow: $x $y $blur $spread $color;
  -moz-box-shadow: $x $y $blur $spread $color;
  box-shadow: $x $y $blur $spread $color;
}

@mixin transition($property: all, $duration: $transition-time, $timing-function: $transition-timing-function, $delay: 0s) {
  -webkit-transition: $property $duration $timing-function $delay !important;
  -moz-transition: $property $duration $timing-function $delay !important;
  -o-transition: $property $duration $timing-function $delay !important;
  transition: $property $duration $timing-function $delay !important;
}