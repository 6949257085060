
@import "../../app/static/css/variables";
.loader {
  position: relative;
  width: 100%;
  height: 200px;
}

.bundles {
  &__block {
    &.block-form {
      font-family: $font-family_sans-serif;
      padding: $indent-l 19.5rem 10.5rem;
      .block__title {
        margin-bottom: $indent-m;
      }
    }
  }
}

@media (max-width: $screen__xl - 1) {
  .bundles {
    &__block {
      &.block-form {
        padding: $indent-l 9.5rem 10.5rem;
      }
    }
  }
}

@media (max-width: $screen__m - 1) {
  .bundles {
    &__block {
      &.block-form {
        padding: $indent-m $indent-xs;
      }
    }
  }
}
