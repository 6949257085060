.dropdown-menu {
  position: relative;
  &:disabled {
    pointer-events: none;
    input, .block__title::before {
      opacity: $opacity_sub;
    }
  }
  &__label {
    @include transition;
    transition-property: transform, opacity;
    transform: translateZ(0);
    //will-change: transform, opacity;
  }
  .block {
    &__title {
      position: relative;
      display: flex;
      padding-right: $indent-m;
      @include transition;
      transition-property: transform, opacity;

      //will-change: transform, opacity;
      input {
        @include input-reset;
        @include transition;
        @include placeholder {
          color: var(--hs-text-color) !important;
          opacity: $opacity_disabled;
        }
        position: relative;
        color: var(--hs-color-link);
        font-family: var(--hs-font-family-primary);
        font-weight: $font-weight_regular;
        font-size: $font-size_default;
        line-height: $line-height_default;
        transform: translateZ(0);
        transition-property: color, border, z-index;
        cursor: pointer;

        &:hover::placeholder {
          opacity: 1;
        }
      }
      &::before {
        @include transition(color);
        font-family: $icon-font;
        content: $icon-angle-down;
        position: absolute;
        font-size: $icon-size;
        right: $indent-m;
        top: 50%;
        transform: translate(0, -50%);
        pointer-events: none;
      }
      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 1px;
        background-color: var(--hs-border-color);
      }
    }
    &__content {
      position: absolute !important;
      z-index: 4;
      top: 0;
      left: 0;
      width: auto;
      min-width: 100%;
      @include transition;
      transition-property: transform, opacity;
      box-sizing: border-box;
      transform-origin: 0 0;
      box-shadow: 1px 10px 40px rgba(0, 0, 0, 0.1);
      background-color: $color_light;
      transform: translate3d(0, -10px, 0);
      &.dropdown-enter,
      &.dropdown-leave-to {
        pointer-events: none;
        transform: translate3d(0, 20px, 0);
        opacity: 0;
      }
      .vb-dragger {
        &:hover .vb-dragger-styler {
          opacity: 0.3;
        }
      }
      .content-inner {
        position: relative;
      }
      .line {
        position: absolute;
        transform: translateZ(0);
        left: 0;
        top: 0;
        width: 1px;
        height: 0;
        background-color: var(--hs-border-color);
        transition: top, height;
        transition-duration: $transition-time*.6;
        transition-timing-function: $transition-timing-function;
      }
      li {
        &:not(:last-child) {
          border-bottom: 1px solid $color_fill-light;
        }
        .value {
          will-change: opacity;
          display: block;
          white-space: nowrap;
          padding: $indent-xs $indent-m;
          font-size: $font-size_default;
          text-decoration: none;
          opacity: .65;
          @include transition;
          transition-property: opacity, transform;
          position: relative;
          z-index: 1;
          cursor: pointer;
        }
        &.is-active,
        &:hover{
          .value{
            opacity: 1;
            transform: translate3d(2px, 0, 0)
          }
        }
      }
    }
  }
  &.is-active {
    .block__title {
      transform: translate3d(0, -20px, 0);
      opacity: 0;
      z-index: 2;
    }
    .dropdown-menu__label {
      transform: translate3d(0, -20px, 0);
    }
  }
  &.handle-input{
    .block__content{
      top: calc(100%);
      cursor: pointer;
      transform: none;
      &.dropdown-enter,
      &.dropdown-leave-to {
        transform: translate3d(0, 20px, 0);
        opacity: 0;
      }
      ul{
        width: calc(100% + 20px);
      }
    }
    &.is-active {
      .block__title {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
      .dropdown-menu__label {
        transform: translate3d(0, 0, 0);
      }
    }
  }
}

.dropdown-select {
  position: relative;
  padding: 0 $indent-m;
  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: var(--hs-border-color);
  }
  &:disabled {
    pointer-events: none;
    select, i {
      opacity: $opacity_sub;
    }
  }
  select {
    width: 100%;
    @include input-reset;
    padding-right: $indent-m;
    padding-left: $indent-m;
    font-family: var(--hs-font-family-primary);
    font-weight: $font-weight_regular;
    font-size: $font-size_default;
    cursor: pointer;
    @include transition(color);
    line-height: $line-height_default;
  }
  i {
    font-family: $icon-font;
    font-size: $font-size_m;
    pointer-events: none;
    color: var(--hs-text-color);
  }
  &__label{
    position: relative;
  }
}

.field__dropdown::before {
  @include transition(color);
  transition: color $transition-time $transition-timing-function;
  font-family: $icon-font;
  content: $icon-angle-down;
  position: absolute;
  font-size: $icon-size;
  right: $indent-m;
  top: 50%;
  transform: translate(0, -50%);
  pointer-events: none;
}
