
@import '@/app/static/css/variables';

$breakpoint-mobile: 683px;

.v-sheet.theme--dark .v-skeleton-loader .v-skeleton-loader__card-heading {
  background: lighten($theme-dark-bg_el, 3%);
}

.v-skeleton-loader {
  &__bone {
    .v-skeleton-loader__heading {
      width: 25%;
    }
  }
  &__card {
    min-height: inherit;
    display: flex;
    @media (max-width: $breakpoint-mobile) {
      flex-direction: column;
    }
  }
  &__image {
    min-height: inherit;
    width: 50%;
    height: auto;
    @media (max-width: $breakpoint-mobile) {
      width: 100%;
    }
  }
  &__card-heading {
    width: 50%;
    margin-top: auto;
    @media (max-width: $breakpoint-mobile) {
      width: 100%;
    }
    .v-skeleton-loader__heading.v-skeleton-loader__bone {
      margin-left: auto;
      width: 45%;
      height: 44px;
      border-radius: 0;
      @media (max-width: 830px) {
        width: 90%;
      }
      @media (max-width: $breakpoint-mobile) {
        width: 45%;
      }
    }
  }
}
