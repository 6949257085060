$_hamburger-width: 1.25rem;
$_hamburger-height: 1rem;

.mobile-menu {
  font-family: var(--hs-font-family-primary);
  .back-btn {
    font-size: 1rem;
  }
}
.hamburger {
  @include button-reset;
  @include activebg;
  width: $_hamburger-width;
  height: $_hamburger-height;
  span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    border-radius: 1px;
    background: var(--hs-text-color);
    opacity: $opacity_sub;
    transform: translate3d(0, 0, 0);
    transform-origin: 50% 50%;
    @include transition;
    transition-property: transform, opacity;

    &:first-child{
      top: 0;
    }
    &:nth-child(2) {
      top: 50%;
    }
    &:last-child{
      bottom: 0;
    }
  }
  &.is-active{
    span{
      &:first-child{
        transform: translate3d(0, 6px, 0) rotate(45deg);
      }
      &:nth-child(2) {
        transform: translate3d(-10px, 0, 0);
        opacity: 0;
      }
      &:last-child{
        transform: translate3d(0, -7px, 0) rotate(-45deg);
      }
    }
  }
}
@media (max-width: $screen__s - 1) {
  .mobile-menu{
    left: $indent-m;
  }
}
