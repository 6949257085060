
@import '@/app/static/css/variables';

.input-image {
  display: flex;

  .image-container {
    width: 75px;
    height: 75px;
    background: darken($color_light, 3%);
    flex: none;
    img {
      width: 75px;
      height: 75px;
      object-fit: contain;
    }
    .placeholder {
      position: relative;
      width: 100%;
      height: 100%;
      background: rgba(gray, 0.1);
    }
  }
}
