
@import '@/app/static/css/variables';

.loader {
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  font-size: 0;
}
.spinner {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  -webkit-animation: rotate-all 1s $transition-timing-function infinite;
  animation: rotate-all 1s $transition-timing-function infinite;

  .right-side,
  .left-side {
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    overflow: hidden;
  }

  .left-side {
    left: 0;
    .bar {
      border-right: none;
      transform: rotate(5deg);
      transform-origin: right center;
      animation: rotate-left 0.75s $transition-timing-function infinite
        alternate;
      &::after {
        bottom: -5px;
        right: -2px;
      }
    }
  }

  .right-side {
    right: 0;
    .bar {
      border-radius: 0 200px 200px 0;
      border-left: none;
      transform: rotate(-5deg);
      transform-origin: left center;
      animation: rotate-right 0.75s $transition-timing-function infinite
        alternate;
      &::after {
        bottom: -5px;
        left: -2px;
      }
    }
  }

  .bar {
    $color: currentColor;
    width: 100%;
    height: 100%;
    border-radius: 200px 0 0 200px;
    border: 5px solid $color;
    position: relative;
    &::after {
      content: '';
      width: 5px;
      height: 5px;
      display: block;
      background: $color;
      position: absolute;
      border-radius: 5px;
    }
  }
}

@keyframes rotate-left {
  to {
    transform: rotate(30deg);
  }
  from {
    transform: rotate(175deg);
  }
}

@keyframes rotate-right {
  from {
    transform: rotate(-175deg);
  }
  to {
    transform: rotate(-30deg);
  }
}

@keyframes rotate-all {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
