@import "templates";

@mixin add-icon (
  $_size: $icon-size,
  $_position: left,
  $_indent: $_size * 1.5 + $indent-s
) {
  padding-#{$_position}: $_indent;
  position: relative;
  line-height: $line-height_default;
  i {
    display: block;
    position: absolute;
    #{$_position}: 0;
    top: 50%;
    transform: translate(0, -50%);
    height: calc(#{$_size} * 1.5);
    width: calc(#{$_size} * 1.5);
    line-height: calc(#{$_size} * 1.5);
    overflow: visible;
    text-align: center;
    &::before {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: $_size;
      transform: translate(-50%, -50%);
    }
  }
}

@mixin icon-rounded($height: 1.25rem, $width: 1.25rem, $fontSize: 0.6rem) {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: $height;
  width: $width;
  font-size: $fontSize;
}

@mixin minus($_size: $icon-size, $_color: $color_main) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    background-color: $_color;
  }
  &::after {
    width: $_size;
    height: 1px;
  }
}

@mixin fullwidthline($_position: top) {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    #{$_position}: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100vw;
    height: 1px;
    background: currentColor;
  }
}

@mixin toolbar {
  @include box-shadow(0, 5px, 20px, 0, $color_main);
  position: fixed;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  z-index: 9;
  padding: 10px 0;
}

@mixin overlay {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity $transition-time_longer $transition-timing-function, visibility 0s $transition-timing-function $transition-time_longer;
  background-color: $color_main;
  &.is-active {
    opacity: .4;
    visibility: visible;
    transition-delay: 0s;
    cursor: pointer;
  }
}

@mixin activebg {
  position: relative;
  cursor: pointer;
  &::before {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    left: 0;
    opacity: 0;
    background-color: $color_main;
    visibility: hidden;
    transition: opacity $transition-time_longer $transition-timing-function, visibility 0s $transition-timing-function $transition-time_longer;
    z-index: 6;
  }
  &.is-active {
    &::before {
      opacity: .8;
      visibility: visible;
      transition-delay: 0s;
    }
  }
}

@mixin asideMenu {
  @include transition;
  transition-property: transform, opacity;
  transform: translate(-100%, 0);
  opacity: 0;
  z-index: 100;
  &.is-active {
    opacity: 1;
    transform: translate(0, 0);
  }
  @media (max-width: $screen__l - 1) {
    [data-role="item"]{
      opacity: 0;
      transform: translate3d(0, 30px, 0);
      transition-duration: calc($transition-time * 0.3);
      transition-delay: .2s;
    }
    &.is-active {
      [data-role="item"] {
        transition: transform, opacity;
        transition-duration: calc($transition-time * 0.8);
        transition-timing-function: $transition-timing-function;
        @for $i from 1 through 100 {
          &:nth-child(#{$i}) {
            transition-delay: calc($transition-time / 2) + calc($i / 15);
          }
        }
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    }
  }
}