
@import '@/app/static/css/variables';

.v-expansion-panel-header,
:deep(.v-expansion-panel-content__wrap) {
  padding-left: 0;
  padding-right: 0;
}

:deep(.v-data-table) {
  @media (min-width: $screen__m) {
    width: 50%;
  }
}
