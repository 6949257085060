.menu-content {
  &__heading {
    border-radius: $form_border-radius;
    font-family: var(--hs-font-family-primary);
    flex-wrap: wrap;
    line-height: $line-height_default;
    i {
      font-size: $font-size_m;
      margin-right: $indent-s;
    }
    p {
      font-size: $font-size_m;
    }
    .actions-toolbar{
      margin-left: auto;
    }
  }
  &__container {
    border-radius: $form_border-radius;
    .items-list {
      .item {
        background-color: $color_light;
        border-radius: $form_border-radius;
        &:not(:last-child) {
          margin-bottom: $indent-l;
        }
      }
    }
  }
  &__item {
    &_dropdown {
      padding: $indent-m 0;
      border-bottom: 1px solid var(--hs-border-color);
      &:not(:first-child) {
        .item__content {
          .text {
            padding-top: $indent-s;
          }
        }
      }
      &:first-child {
        padding-top: 0;
      }
      .item {
        &__heading {
          font-size: $font-size_default;
          color: var(--hs-text-color);
          line-height: $line-height_default;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          .icon {
            margin-right: $indent-l;
            i {
              opacity: .3;
              @include transition(opacity);
              &::after {
                transition: transform, opacity;
                transition-duration: $transition-time_longer;
                transition-timing-function: $transition-timing-function;
              }
              &::before,
              &::after {
                will-change: transform;
              }
            }

          }
        }
        &__content {
          overflow: hidden;
          .text {
            p {
              line-height: 2em;
              font-size: $font-size_default;
            }
          }
        }
      }
      &.is-active {
        .item__heading {
          i {
            opacity: 1;
            &::after {
              transform: translate(-50%, -50%) rotate(90deg);
              opacity: 0;
            }
          }
        }
      }
    }
  }
}

.module__my-account {
  .menu-content {
    &__container {
      padding: $indent-l;
      .my-account__edit {
        width: 50%;
      }
      .company-check {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: $indent-xs 0;
        .field {
          &__title {
            font-size: $font-size_default;
            color: var(--hs-text-color);
          }
        }
      }
    }
  }
}

.module__payment-methods {
  .method {
    font-family: var(--hs-font-family-primary);
    display: flex;
    &__img {
      padding: $indent-m;
      text-align: center;
      flex-basis: 6.2rem;
    }
    &__body {
      padding: $indent-m $indent-xs;
      position: relative;
      flex-grow: 1;
    }
    &__type,
    &__exp,
    &__owner {
      margin-bottom: $indent-xxs;
      p {
        font-size: $font-size_default;
        line-height: $line-height_default;
        text-transform: capitalize;
      }
    }
    &__set-default {
      padding-top: $indent-xs;
      .is-default {
        pointer-events: none;
        p {
          font-size: $font-size_default;
          line-height: $line-height_default;
          color: var(--hs-text-color);
        }
      }
    }
    &__actions {
      position: absolute;
      top: $indent-m;
      right: $indent-m;
      .action__remove {
        cursor: pointer;
        display: flex;
        align-items: center;
      }
      .value {
        text-transform: uppercase;
        font-size: .5rem;
        font-weight: $font-weight_semibold;
        margin-right: $indent-xxs;
        line-height: $line-height_default;
      }
      i {
        font-size: $font-size_s;
      }
    }
    &.is-active {
      .method__actions .action__remove {
        opacity: $opacity_sub;
        pointer-events: none;
      }
    }
  }
}

.module__notifications {
  font-family: var(--hs-font-family-primary);
  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: $indent-m;
    &__label {
      position: absolute;
      top: $indent-xxs;
      left: $indent-xxs;
      border-radius: 50%;
      height: $indent-xxs;
      width: $indent-xxs;
      animation: notify 1.5s infinite alternate;
      &.green {
        background-color: var(--v-success-base);
      }
      &.yellow {
        background-color: var(--v-warning-lighten2);
      }
      &.red {
        background-color: var(--v-error-base);
      }
    }
    &__content {
      padding-right: $indent-s;
      .title {
        font-weight: $font-weight_light;
        color: var(--hs-text-color);
        font-size: $font-size_default;
        &::after {
          content: ':';
          margin-right: $text-indent;
        }
      }
      .content {
        font-size: $font-size_m;
        color: var(--hs-text-color);
        line-height: $line-height_default;
      }
    }
    &__message {
      font-size: $font-size_s;
      line-height: $line-height_default;
      white-space: nowrap;
      &.green {
        color: var(--v-success-base);
      }
      &.yellow {
        color: var(--v-warning-lighten2);
      }
      &.red {
        color: var(--v-error-base);
      }
    }
  }
}

@keyframes notify {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  100% {
    transform: scale(1.2);
    opacity: 1;
  }
}

.module__my-orders {
  font-family: var(--hs-font-family-primary);
  .order {
    &__heading,
    &__body{
      display: flex;
      padding: $indent-m;
    }
    &__heading{
      border-bottom: 1px solid $color_fill-light;
    }
    &__total{
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
    &__grand-total{
      margin-bottom: $indent-xs;
      .price{
        &-box{
          text-align: center;
        }
        &-info,
        &-count{
          font-size: $font-size_s;
          text-transform: uppercase;
        }
        &-info{
          margin-right: $indent-xxs;
        }
        &-count{
          font-weight: $font-weight_semibold;
        }
      }
    }
    &__actions{
      width: 9rem;
      .actions-toolbar{
        button,
        a{
          text-align: center;
        }
        .action.tertiary{
          margin: $indent-s 0;
          a{
            font-weight: $font-weight_regular;
            text-decoration: underline;
            font-size: $font-size_s;
          }
        }
      }
    }
    &__image{
      margin-right: $indent-m;
      flex-shrink: 0;
      width: 165px;
      height: 130px;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__content{
      flex-grow: 1;
    }
    &__dates{
      padding-bottom: $indent-s;
      margin-bottom: $indent-xxs;
      border-bottom: 1px solid $color_fill-light;
      display: inline-flex;
      align-items: center;
      font-size: $font-size_s;
      color: var(--hs-text-color);
      i {
        margin-right: $indent-xxs;
        font-size: $font-size_xs;
        &.range{
          font-size: .5rem;
          color: var(--hs-text-color);
        }
      }
      .title,
      .date{
        margin-right: $indent-xxs;
      }
    }
    &__room-info{
      padding-right: $indent-l;
      .title{
        color: var(--hs-text-color);
        font-size: var(--hs-font-size-title);
        font-family: var(--hs-font-family-title);
        line-height: $line-height_default;
        font-weight: $font-weight_semibold;
        margin-bottom: $indent-xxs;
      }
      .price{
        &-count,
        &-info{
          color: var(--hs-text-color);
          line-height: $line-height_default;
        }
        &-count{
          font-size: $font-size_l;
          margin-right: $indent-xxs;
        }
        &-info{
          font-size: $font-size_xs;
          opacity: 0.6;
        }
      }
    }
  }
  .heading {
    &__item {
      &.number {
        margin: 0 $indent-l;
      }
      &.status {
        margin-left: auto;
        width: 9rem;
        i {
          margin-right: $indent-xxs;
          font-size: $font-size_s;
        }
      }
    }
    &-item {
      &__title {
        font-weight: $font-weight_light;
        font-size: $font-size_default;
        line-height: $line-height_default;
        color: var(--hs-text-color);
      }
      &__content {
        line-height: $line-height_default;
        font-weight: $font-weight_regular;
        font-size: $font-size_m;
        color: var(--hs-text-color);
        &.success {
          color: var(--v-success-base);
        }
        &.notice {
          color: var(--v-warning-lighten2);
        }
        &.error {
          color: var(--v-error-base);
        }
      }
    }
  }
}


@media (max-width: $screen__s - 1) {
  .customer-account{
    &__content-wrapper{
      padding-top: 0;
      padding-bottom: $indent-s;
    }
  }
  .menu-content{
    &__heading{
      border-radius: 0;
      margin-left: -$indent-s;
      margin-right: -$indent-s;
      margin-bottom: $indent-m;
      .actions-toolbar{
        flex-basis: 100%;
        margin-top: $indent-s;
        padding-top: $indent-m + $indent-xxs;
        padding-bottom: $indent-xs;
        @include fullwidthline();
      }
    }
    &__container{
      margin-left: -$indent-xs;
      margin-right: -$indent-xs;
      .items-list {
        .item {
          &:not(:last-child) {
            margin-bottom: $indent-s;
          }
        }
      }
    }
  }
  .module{
    &__my-orders{
      .order{
        &__body{
          flex-wrap: wrap;
        }
        &__image{
          width: auto;
          flex-basis: 100%;
          margin-right: 0;
          margin-bottom: $indent-s;
          height: 140px;
        }
        &__content{
          padding-bottom: $indent-s;
          margin-bottom: $indent-xs;
          position: relative;
          &::after{
            bottom: 0;
            left: -$indent-m;
            position: absolute;
            content: '';
            width: calc(100% + 2 * #{$indent-m});
            height: 1px;
            background-color: $color_fill-light;
          }
        }
        &__total{
          flex-basis: 100%;
        }
        &__actions{
          width: 100%;
        }
      }
    }
    &__my-account{
      .menu-content{
        &__container{
          padding: $indent-m;
          .my-account{
            &__edit{
              width: 100%;
            }
          }
        }
      }
    }
    &__payment-methods,
    &__notifications{
      .menu-content{
        &__heading{
          margin-bottom: $indent-xs;
        }
        &__container{
          .items-list{
            .item{
              &:not(:last-child) {
                margin-bottom: $indent-xs;
              }
            }
          }
        }
      }
      .method{
        &__img{
          padding: $indent-xs;
          flex: 0 0 100px;
        }
      }
    }
    &__privacy-policy{
      .menu-content{
        &__heading{
          margin-bottom: $indent-xs;
        }
        &__container{
          padding: $indent-m;
        }
      }
    }
  }
}

@media (max-width: $screen__xs - 1) {
  .module{
    &__my-orders{
      .heading__item{
        &.number,
        &.status,
        &.date{
          flex-basis: 100%;
          display: flex;
          justify-content: space-between;
          margin: 0 0 $indent-xxs 0;
          padding-bottom: $indent-xxs;
          &:not(:last-child) {
            border-bottom: 1px dashed var(--hs-border-color);
          }
        }
      }
      .order{
        &__heading{
          flex-wrap: wrap;

        }
      }
    }
    &__my-account{
      .menu-content{
        &__container{
          .field.company-check{
            .field{
              &__title{
                flex-basis: 100%;
                margin-bottom: $indent-s;
              }
            }
          }
        }
      }
    }
    &__notifications{
      .item{
        &__content{
          .title,
          .content{
            display: block;
          }
          .title{
            margin-bottom: $indent-xxs;
          }
        }
      }
    }
  }
}

@media (max-width: $screen__xxs - 1) {
  .module{
    &__payment-methods{
      .method{
        &__body{
          padding: $indent-m 0;
          width: calc(100vw - 2*#{$indent-xxs} - 100px);
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
        }
        &__set-default{
          width: calc(100vw - 2 * (#{$indent-xxs + $indent-xs}));
          align-self: flex-end;
        }
      }
    }
  }
}
